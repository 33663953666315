import { useApp } from 'context';
import { getProducts } from 'api/products';
import { useEffect, useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { addSectionProduct, removeSectionProduct } from 'api/blog';

export const ContentModal = ({ setEditModal, content, setContent, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', padding: 20, zIndex: 10000000 }} className='shadow'>
        <textarea style={{ marginTop: 0, height: 120 }} className='input-styles light-grey-bg' placeholder='CONTENT' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={handleCancel}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={() => handleUpdate()}>SAVE</div>
        </div>
      </div>
    </>
  )
}

export const EmbedModal = ({ setEditModal, content, content2, content3, setContent, setContent2, setContent3, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', padding: 20, zIndex: 10000000 }} className='shadow'>
        <textarea style={{ marginTop: 0, height: 120, width: '100%' }} className='input-styles light-grey-bg mb-2' placeholder='CONTENT' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='WIDTH i.e. 800 ** OPTIONAL' value={content2} onChange={(e) => setContent2(e.target.value)} />
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='Height i.e. 500 ** OPTIONAL' value={content3} onChange={(e) => setContent3(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={handleCancel}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={() => handleUpdate()}>SAVE</div>
        </div>
      </div>
    </>
  )
}

export const LinkModal = ({ setEditModal, href, setHref, content, setContent, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', padding: 20, zIndex: 10000000 }} className='shadow'>
        <textarea style={{ marginTop: 0, height: 120, width: '100%' }} className='input-styles light-grey-bg' placeholder='LINK CONTENT' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <input style={{ marginTop: 0, marginBottom: 8, width: '100%' }} className='input-styles light-grey-bg' placeholder='https://apple.com' value={href} onChange={(e) => setHref(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={handleCancel}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={handleUpdate}>SAVE</div>
        </div>
      </div>
    </>
  )
}

export const VideoModal = ({ setEditModal, content, content2, content3, setContent, setContent2, setContent3, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', zIndex: 10000000, padding: 20 }} className='shadow'>
        <input style={{ marginTop: 8, marginBottom: 8, width: 420 }} className='input-styles light-grey-bg mb-3' placeholder='https://youtube.com/' value={content} onChange={(e) => setContent(e.target.value)} />
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='WIDTH i.e. 800 ** OPTIONAL' value={content2} onChange={(e) => setContent2(e.target.value)} />
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='Height i.e. 500 ** OPTIONAL' value={content3} onChange={(e) => setContent3(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={() => setEditModal(false)}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={handleUpdate}>SAVE</div>
        </div>
      </div>
    </>
  )
}

export const ImageModal = ({ setEditModal, path, handleUpdate, href, setHref }) => {
  const [tempImage, setTempImg] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const _path = displayImage ? displayImage : path;

  const handleImg = (e) => {
    setTempImg(e.target.files[0])
    setDisplayImage(URL.createObjectURL(e.target.files[0]));
  }

  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', zIndex: 10000000, padding: 20 }} className='shadow'>
        <div style={{ position: 'relative', width: 300, border: '1px solid rgb(92, 125, 109)', borderRadius: 6, overflow: 'hidden' }}>
          <input type="file" accept="image/*" multiple={false} style={{ position: 'absolute', left: 0, top: 0, opacity: 0, width: '100%', height: '100%', zIndex: 1 }} onChange={(e) => handleImg(e)} />
          <img style={{ position: 'relative', width: 300, objectFit: 'cover', zIndex: 0 }} alt='' src={_path} />
        </div>
        <input style={{ marginTop: 8, marginBottom: 8, width: '100%' }} className='input-styles light-grey-bg' placeholder='https://apple.com' value={href} onChange={(e) => setHref(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={() => setEditModal(false)}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={() => handleUpdate(tempImage)}>SAVE</div>
        </div>
      </div>
    </>
  )
}

export const ProductModal = ({ section, editModal, existingProducts, setEditModal, handleProductData }) => {
  const { setLoading, addToast } = useApp();
  const [focus, setFocus] = useState(false);
  const [searchVal, setSearchVal] = useState([]);
  const [optionProducts, setOptionProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const handleProducts = async () => {
    setProductsLoading(true);
    try {
      let existingIds = existingProducts?.map((ex) => ex?.id);
      let filters = { id: { _not: existingIds } };

      if (searchVal?.length > 0) {
        filters = {...filters, name: { _iLike: `%25${searchVal}%25` } }
      }

      let data = await getProducts({ filter: filters });
      setOptionProducts(data);
      setProductsLoading(false);
    } catch(err) {
      setProductsLoading(false);
    }
  }

  useEffect(() => {
    if (editModal) {
      handleProducts()
    }
  }, [editModal])

  const handleAddProduct = async (p) => {
    if (existingProducts?.length < 4) {
      try {
        setLoading(true);
        await addSectionProduct(section?.id, [p?.id]);
        await handleProductData();
        setLoading(false);
      } catch(err) {
        setLoading(false);
        addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
      }
    } else {
      addToast({ title: "Max 4 products per section", body: 'Remove one before adding another', type: "ERROR" });
    }
    setFocus(false);
  }

  const handleRemoveProduct =  async (p) => {
    try {
      setLoading(true);
      await removeSectionProduct(section?.id, [p?.id]);
      await handleProductData();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  const handleType = (e) => {
    let newVal = e.target.value;
    setSearchVal(newVal);
    handleProducts();
  }

  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', zIndex: 10000000, padding: 20 }} className='shadow'>
        <div style={{ position: 'relative' }}>
          Add New Product
          <input value={searchVal} onChange={handleType} onClick={() => setFocus(true)} style={{ color: 'white', fontWeight: 600, marginTop: 4, cursor: 'pointer', width: '100%', border: '1px solid rgb(92, 125, 109)', outline: 'none', height: 40, paddingLeft: 12 }} placeholder="SEARCH PRODUCTS" className="className='input-styles light-grey-bg js-ac'" />
          {focus && (
            <div style={{ position: 'absolute', left: 0, top: 67, width: '100%', background: 'rgb(54, 54, 54)', border: '1px solid rgb(92, 125, 109)', maxHeight: 420, overflowY: 'auto', boxShadow: '2px 2px 6px rgba(0,0,0,.2)', zIndex: 100000 }}>
              {optionProducts?.map((p, key) => (
                <div key={key} style={{ width: '100%', color: 'white', cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className='js-ac p-2' onClick={() => handleAddProduct(p)}>
                  {p?.name?.slice(0, 39)}
                  {p?.name?.length > 39 && "..."}
                </div>
              ))}
            </div>
          )}
        </div>
        {existingProducts?.length > 0 && (
          <div className='mt-3'>
            Existing Products
            {existingProducts?.map((p, key) => (
              <div className='jb-ac mt-1 light-grey-bg p-1' style={{ maxWidth: 500 }} key={key}>
                <div style={{ maxWidth: '90%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{p.name}</div>
                <div onClick={() => handleRemoveProduct(p)}><IoTrashOutline /></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
