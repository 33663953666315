import { useApp } from 'context';
import { useEffect } from 'react';
import Checkbox from 'components/Checkbox';
import { editActivity, uploadActivityIcon } from 'api/activities';

const Activities = () => {
  const { activities, handleActivities, setLoading, addToast } = useApp();
  const sorted = activities?.sort((a, b) => a.name.localeCompare(b.name))

  useEffect(() => {
    handleActivities()
  }, [])

  const handleEdit = async (id, changes) => {
    try {
      setLoading(true)
      await editActivity(id, changes);
      handleActivities()
      setLoading(false)
    } catch(err) {
      setLoading(false)
      addToast({ title: "Error", body: 'Error editing activity', type: "ERROR" });
    }
  }

  const handleIcon = async (e, id, type = 'location') => {
    try {
      setLoading(true)
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file, file?.type);
      formData.append("activityId", id);
      formData.append("iconType", type);
      await uploadActivityIcon(formData)
      setLoading(false)
    } catch(err) {
      setLoading(false)
      addToast({ title: "Error", body: 'Error uploading icon', type: "ERROR" });
    }
  }

  return(
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      {sorted?.map((activity, idx) => (
        <Activity key={idx} idx={idx} activity={activity} handleEdit={handleEdit} handleIcon={handleIcon} sorted={sorted} />
      ))}
    </div>
  )
}

export default Activities;

const Activity = ({ activity = {}, handleEdit, handleIcon, sorted, idx }) => {
  const isLast = sorted?.length === idx + 1;
  const postCount = activity?.postCount;
  const getCountColor = () => {
    if (postCount > 10) {
      return 'green'
    } else if (postCount > 5) {
      return 'yellow'
    } else if (postCount > 1) {
      return 'orange'
    } else {
      return 'red'
    }
  }

  return(
    <div style={{ width: '100%', padding: '20px 0px', color: 'white', borderBottom: isLast ? '' : '1px solid rgba(255,255,255,.12)', height: 83 }} className='d-flex justify-content-between align-items-center'>
      <div className='d-flex justify-content-start align-items-center'>
        <Checkbox value={activity?.active} setValue={(newValue) => handleEdit(activity?.id, { active: newValue })} />
        <div className='ms-3'>
          {activity?.name}
        </div>
      </div>
    </div>
  )
}

// <div style={{ position: 'relative', width: 50, height: 50 }}>
//   <input type="file" accept="image/*" multiple={false} style={{ position: 'absolute', left: 0, top: 0, opacity: 0, width: '100%', height: '100%', zIndex: 1 }} onChange={(e) => handleIcon(e, activity?.id)} />
//   <img style={{ position: 'relative', width: 50, height: 50, objectFit: 'contain', zIndex: 0 }} alt='' src={`${CDN_ROUTE}icons/${activity?.id}/location`} />
// </div>
