import { client } from './client';
import { addUrlQuery } from './utils';

export const getContactFormSubmissions = async () => {
  try {
    const url = addUrlQuery(`reports`, { filter: { status: { _not: `DISMISSED` }, category: { _eq: `CONTACT-FORM` } } });
    let response = await client.get(url);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
}

export const getReports = async () => {
  try {
    const url = addUrlQuery(`reports`, { filter: { status: { _not: `DISMISSED` }, category: { _not: `CONTACT-FORM` } } });
    let response = await client.get(url);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
}

export const dismissReport = async (id) => {
  try {
    let response = await client.put(`reports/${id}`, { status: "DISMISSED" });
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
}
