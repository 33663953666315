import { useApp } from 'context';
import { useEffect, useState } from 'react';
import { handlePost } from 'utils/upload';
import { IoCogOutline, IoAppsOutline, IoTrashOutline, IoArrowUp, IoArrowDown, IoChevronBackOutline } from 'react-icons/io5';
import { BsX } from 'react-icons/bs';
import {
  getPage,
  addPage,
  getPages,
  updatePage,
  deletePage,
  addSection,
  removeSection,
  updateSection,
  uploadBlogImage,
  addSectionProduct,
  removeSectionProduct
} from 'api/docs';
import { getFile } from 'api/files';
import { uploadFile } from 'api/uploader';
import { useMeasure } from "react-use";
import { getProducts } from 'api/products';
import { v4 as uuidv4 } from "uuid";

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Pages = () => {
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState({});
  const selectedPageId = selectedPage?.id || null;

  const handlePages = async () => {
    let _pages = await getPages();
    setPages(_pages)
  }

  useEffect(() => {
    if (!selectedPageId) {
      handlePages();
    }
  }, [selectedPageId])

  const handleAdd = async () => {
    let newBlog = await addPage();
    setSelectedPage(newBlog)
  }

  return(
    <div style={{ width: '100%' }}>
      {!selectedPage?.id && <AllPages pages={pages} handleAdd={handleAdd} setSelectedPage={setSelectedPage} />}
      {selectedPage?.id && <Page page={selectedPage} handlePages={handlePages} setSelectedPage={setSelectedPage} />}
    </div>
  )
}

export default Pages;

const AllPages = ({ pages = [], handleAdd, setSelectedPage }) => {
  const sortedPages = pages?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));
  const corePages = sortedPages?.filter((b) => b?.pageType === 'core');
  const docPages = sortedPages?.filter((b) => b?.pageType !== 'core');

  return(
    <div  style={{ background: 'black', height: '100vh', borderLeft: '1px solid rgba(255,255,255,.25)' }}>
      <div style={{ width: '100%', padding: 20 }} className='je-ac'>
        <div className='btn1' style={{ paddingLeft: 22, paddingRight: 22, width: 200 }} onClick={handleAdd}>ADD DOC</div>
      </div>
      <div className='container'>
        <div className='row mt-3'>
          <div style={{ fontSize: 22, fontWeight: 700 }}>CORE PAGES</div>
          {corePages?.map((page, key) => (
            <div key={key} className='col-12 mb-2'>
              <div onClick={() => setSelectedPage(page)} style={{ cursor: 'pointer', background: '#1f1e20', borderRadius: 6, overflow: 'hidden', textAlign: 'left' }} className='shadow-sm ps-3 pe-3 pt-2 pb-2'>
                <div className='truncate' style={{ fontWeight: 700 }}>{page?.name}</div>
                <div className='truncate' style={{ fontSize: 12, color: 'rgb(210,210,210)' }}>{page?.slug}</div>
              </div>
            </div>
          ))}
          <div className='mt-3' style={{ fontSize: 22, fontWeight: 700 }}>DOC LIBRARY PAGES</div>
          {docPages?.map((page, key) => (
            <div key={key} className='col-12 mb-2'>
              <div onClick={() => setSelectedPage(page)} style={{ cursor: 'pointer', background: '#1f1e20', borderRadius: 6, overflow: 'hidden', textAlign: 'left' }} className='shadow-sm ps-3 pe-3 pt-2 pb-2'>
                <div className='truncate' style={{ fontWeight: 700 }}>{page?.name}</div>
                <div className='truncate' style={{ fontSize: 12, color: 'rgb(210,210,210)' }}>{page?.slug}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Page = ({ page, handlePages, setSelectedPage }) => {
  const [settingsModal, setSettingsModal] = useState(false);
  const [sectionsModal, setSectionsModal] = useState(false);
  const sortedSections = page?.sections?.sort((a, b) => a.position - b.position)

  const isSpacer = (section) => {
    let sTypes = ['Spacer', 'Spacer LG', 'Spacer MD', 'Spacer SM'];
    if (sTypes?.includes(section?.sectionType)) {
      return true
    }
  }

  const GetSection = ({section}) => {
    if (section?.sectionType === 'Title') {
      return <Title page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Subtitle') {
      return <SubTitle page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'SubSubtitle') {
      return <SubSubTitle page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Paragraph') {
      return <Paragraph page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Image') {
      return <Image page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (isSpacer(section)) {
      return <Spacer section={section} />
    } else if (section?.sectionType === 'Link') {
      return <Link page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Line') {
      return <Line page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Product') {
      return <Product page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'ImageTitleText') {
      return <ImageTitleText page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'TitleTextImage') {
      return <TitleTextImage page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'ImageText') {
      return <ImageText page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'TextImage') {
      return <TextImage page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Video') {
      return <Video page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Embed') {
      return <Embed page={page} section={section} setSelectedPage={setSelectedPage} />
    } else if (section?.sectionType === 'Bullet') {
      return <Bullet page={page} section={section} setSelectedPage={setSelectedPage} />
    } else {
      return null;
    }
  }

  const handleClose = async () => {
    try {
      handlePages()
      setSelectedPage({})
    } catch(err) {
      console.log(err);
    }
  }

  return(
    <>
      <div>
        <div style={{ position: 'fixed', left: 260, top: 0, width: 'calc(100% - 260px)', height: 113, background: 'black' }} className='jc-ac'>HEADER</div>
        <div style={{ position: 'fixed', left: 280, top: 20 }} onClick={handleClose}><IoChevronBackOutline style={{ fontSize: 21 }} /></div>
        <div style={{ position: 'fixed', right: 20, top: 20 }} onClick={() => setSettingsModal(true)}><IoCogOutline style={{ fontSize: 21 }} /></div>
        <div style={{ position: 'fixed', right: 20, top: 60 }} onClick={() => setSectionsModal(true)}><IoAppsOutline style={{ fontSize: 20 }} /></div>
        <div style={{ width: '100%', height: 113, background: 'black' }} />
        <div className='container mt-3 pb-5 jc-as'>
          <div style={{ maxWidth: 1000, width: '100%' }}>
            <div className='mb-2 mt-1' style={{ color: 'white', fontSize: 32, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>{page?.name || "PAGE NAME"}</div>
            {sortedSections?.map((s, key) => (
              <GetSection section={s} key={key} />
            ))}
          </div>
        </div>
      </div>
      <BlogSettingsModal page={page} open={settingsModal} setOpen={setSettingsModal} setSelectedPage={setSelectedPage} />
      <BlogSectionsModal page={page} open={sectionsModal} setOpen={setSectionsModal} setSelectedPage={setSelectedPage} />
    </>
  )
}

const BlogSettingsModal = ({ page, open, setOpen, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [desc, setDesc] = useState('');
  const path = page?.coverPhoto ? `${CDN_ROUTE}${page?.coverPhoto}` : '/defaultImg.png';

  const handleBlogUpdate = async (updates) => {
    setLoading(true);
    try {
      await updatePage(page?.id, updates);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Page', type: "ERROR" });
    }
  }

  const handlePublished = () => {
    handleBlogUpdate({ published: !page?.published })
  }

  const handleTitle = () => {
    if (title !== page?.name) {
      handleBlogUpdate({ name: title })
    }
  }

  const handleSlug = () => {
    if (slug !== page?.slug) {
      handleBlogUpdate({ slug: slug })
    }
  }

  const handleDesc = () => {
    if (desc !== page?.description) {
      handleBlogUpdate({ description: desc })
    }
  }

  const handlePageImg = async (e) => {
    try {
      setLoading(true);
      let file = e.target.files[0];
      let fileId = await uploadFile(file, "main", true);
      let updatedBlog = await updatePage(page?.id, { coverPhotoId: fileId });
      setSelectedPage(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  useEffect(() => {
    if (open) {
      setTitle(page?.name || '');
      setSlug(page?.slug || '');
      setDesc(page?.description || '');
    }
    //eslint-disable-next-line
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    try {
      setSelectedPage({})
      await deletePage(page?.id);
    } catch(err) {
      console.log(err);
    }
  }

  if (open) {
    return(
      <>
        <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0 }} onClick={handleClose} />
        <div style={{ position: 'fixed', width: 400, height: '100vh', right: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer', padding: 20 }}>
          <div className='jb-ac' style={{ color: 'white', padding: '15px 2.5px 10px 2.5px', position: 'fixed', width: 370, background: 'black', top: 0 }}>
            <div style={{ fontSize: 20, fontWeight: 600 }}>PAGE OVERVIEW</div>
            <div onClick={handleClose}><BsX style={{ fontSize: 15 }} /></div>
          </div>
          <div style={{ height: 40 }} />
          <div className='d-flex align-items-center' style={{ flexDirection: 'column', paddingBottom: 20 }}>
            <div className='jb-ac mb-2' style={{ width: '100%' }}>
              <div>Publised</div>
              <div onClick={handlePublished} style={{ height: 30, width: 50, padding: 2, position: 'relative', borderRadius: 20 }} className='light-grey-bg'>
                <div style={{ width: 26, height: 26, borderRadius: '50%', background: page?.published ? 'rgb(92, 125, 109)' : 'white', marginLeft: page?.published ? 20 : 0 }} />
              </div>
            </div>
            <input onBlur={handleTitle} style={{ marginTop: 0 }} className='input-styles light-grey-bg' placeholder='NAME' value={title} onChange={(e) => setTitle(e.target.value)} />
            <input onBlur={handleSlug} className='input-styles light-grey-bg' placeholder='SLUG' value={slug} onChange={(e) => setSlug(e.target.value)} />
            <textarea onBlur={handleDesc} style={{ height: 120 }} className='input-styles light-grey-bg' placeholder='DESCRIPTION' value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
            <div className='js-ac mt-3 mb-1' style={{ width: '100%' }}>Cover Photo</div>
            <div style={{ position: 'relative', width: '100%', border: '1px solid rgb(92, 125, 109)' }}>
              <input type="file" accept="image/*" multiple={false} style={{ position: 'absolute', left: 0, top: 0, opacity: 0, width: '100%', height: '100%', zIndex: 1 }} onChange={(e) => handlePageImg(e)} />
              <img style={{ position: 'relative', width: '100%', objectFit: 'cover', zIndex: 0 }} alt='' src={path} />
            </div>
            {!page?.type && (
              <div onClick={handleDelete} className='mt-3 btn1' style={{ background: 'red' }}>DELETE PAGE</div>
            )}
          </div>
        </div>
      </>
    )
  } else {
    return null;
  }
}

const sectionTypes = [
  { type: 'Title' },
  { type: 'Subtitle' },
  { type: 'SubSubtitle' },
  { type: 'Paragraph' },
  { type: 'Image' },
  { type: 'Link' },
  { type: 'Product' },
  { type: 'Line' },
  { type: 'ImageTitleText' },
  { type: 'TitleTextImage' },
  { type: 'ImageText' },
  { type: 'TextImage' },
  { type: 'Spacer LG' },
  { type: 'Spacer MD' },
  { type: 'Spacer SM' },
  { type: 'Video' },
  { type: 'Embed' },
  { type: 'Bullet' },
]

const BlogSectionsModal = ({ page, open, setOpen, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  let sections = page?.sections
  let sortedSections = sections?.sort((a, b) => a.position - b.position)
  let formattedSections = sortedSections?.map((s, idx) => ({ ...s, idx: idx + 1 }))
  let sectionCount = formattedSections?.length;

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddSection = async (type) => {
    setLoading(true)
    try {
      await addSection({ pageId: page?.id, sectionType: type, position: sectionCount + 1 });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }

  const handleRemoveSection = async (section) => {
    setLoading(true)
    try {
      await removeSection(section?.id);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }

  const handleMoveUp = async (bs) => {
    if (bs?.position > 1) {
      try {
        setLoading(true)
        await updateSection(bs?.id, { position: bs?.position - 1 });
        let updatedPage = await getPage(page?.id)
        setSelectedPage(updatedPage)
        setLoading(false)
      } catch(err) {
        addToast({ title: "Error", body: 'Error moving section', type: "ERROR" })
        setLoading(false)
      }
    }
  }

  const handleMoveDown = async (bs) => {
    if (bs?.position !== sectionCount) {
      try {
        setLoading(true)
        await updateSection(bs?.id, { position: bs?.position + 1 });
        let updatedPage = await getPage(page?.id)
        setSelectedPage(updatedPage)
        setLoading(false)
      } catch(err) {
        addToast({ title: "Error", body: 'Error moving section', type: "ERROR" })
        setLoading(false)
      }
    }
  }

  if (open) {
    return(
      <>
        <div style={{ position: 'fixed', width: 400, height: '100vh', right: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer', padding: 17.5, overflowY: 'auto' }}>
          <div className='jb-ac' style={{ color: 'white', padding: '15px 2.5px 10px 2.5px', position: 'fixed', width: 370, background: 'black', top: 0 }}>
            <div style={{ fontSize: 20, fontWeight: 600 }}>PAGE COMPONENTS</div>
            <div onClick={handleClose}><BsX style={{ fontSize: 15 }} /></div>
          </div>
          <div style={{ height: 40 }} />
          <div className='js-ac' style={{ flexWrap: 'wrap' }}>
            {sectionTypes?.map((s, key) => (
              <div onClick={() => handleAddSection(s?.type)} key={key} style={{ width: 86, height: 60, marginLeft: 2.5, marginRight: 2.5, fontSize: 12 }} className='light-grey-bg jc-ac mb-1 blog-item-hover'>
                {s?.type}
              </div>
            ))}
          </div>
          <div style={{ margin: 2.5, paddingTop: 5 }}>
            <div>SECTIONS</div>
            {formattedSections?.map((bs, idx) => (
              <div style={{ width: '100%' }} className='ps-2 pe-2 pt-1 pb-1 light-grey-bg mt-2 jb-ac blog-item-hover' key={idx}>
                <div>{bs?.sectionType}</div>
                <div className='js-ac'>
                  <div className='me-2' onClick={() => handleMoveUp(bs)}><IoArrowUp /></div>
                  <div className='me-2' onClick={() => handleMoveDown(bs)}><IoArrowDown /></div>
                  <div onClick={() => handleRemoveSection(bs)}><IoTrashOutline /></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  } else {
    return null;
  }
}

const ContentModal = ({ setEditModal, content, setContent, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', padding: 20, zIndex: 10000000 }} className='shadow'>
        <textarea style={{ marginTop: 0, height: 120 }} className='input-styles light-grey-bg' placeholder='CONTENT' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={handleCancel}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={() => handleUpdate()}>SAVE</div>
        </div>
      </div>
    </>
  )
}

const EmbedModal = ({ setEditModal, content, content2, content3, setContent, setContent2, setContent3, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', padding: 20, zIndex: 10000000 }} className='shadow'>
        <textarea style={{ marginTop: 0, height: 120, width: '100%' }} className='input-styles light-grey-bg mb-2' placeholder='CONTENT' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='WIDTH i.e. 800 ** OPTIONAL' value={content2} onChange={(e) => setContent2(e.target.value)} />
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='Height i.e. 500 ** OPTIONAL' value={content3} onChange={(e) => setContent3(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={handleCancel}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={() => handleUpdate()}>SAVE</div>
        </div>
      </div>
    </>
  )
}

const LinkModal = ({ setEditModal, href, setHref, content, setContent, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', padding: 20, zIndex: 10000000 }} className='shadow'>
        <textarea style={{ marginTop: 0, height: 120, width: '100%' }} className='input-styles light-grey-bg' placeholder='LINK CONTENT' value={content} onChange={(e) => setContent(e.target.value)}></textarea>
        <input style={{ marginTop: 0, marginBottom: 8, width: '100%' }} className='input-styles light-grey-bg' placeholder='https://apple.com' value={href} onChange={(e) => setHref(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={handleCancel}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={handleUpdate}>SAVE</div>
        </div>
      </div>
    </>
  )
}

const Embed = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const width = section?.content2 ? `${section?.content2}px` : "100%";
  const height = section?.content3 ? `${section?.content3}px` : 562

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
      setContent2(section?.content2)
      setContent3(section?.content3)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content, content2: content2, content3: content3 });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-3 mt-2 jc-ac' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 16, cursor: 'pointer', fontWeight: 600, minHeight: 30, position: 'relative', width: '100%' }}>
        {section?.content1 && <div style={{ width: width, height: height, position: 'relative' }} dangerouslySetInnerHTML={{ __html: section?.content1 }} />}
        {!section?.content1 && <div>EMBED</div>}
        <div onClick={() => setEditModal(true)} style={{ position: 'absolute', left: -50, top: -5 }}>
          <IoCogOutline style={{ fontSize: 15, color: 'white' }} />
        </div>
      </div>
      {editModal && <EmbedModal setEditModal={setEditModal} content={content} content2={content2} content3={content3} setContent={setContent} setContent2={setContent2} setContent3={setContent3} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const Title = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 24, cursor: 'pointer', fontWeight: 600 }}>{section?.content1 || 'TITLE'}</div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const SubTitle = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 20, cursor: 'pointer', fontWeight: 600 }}>{section?.content1 || 'SUBTITLE'}</div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const SubSubTitle = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 18, cursor: 'pointer', fontWeight: 600 }}>{section?.content1 || 'SUBTITLE'}</div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const Paragraph = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSection(section?.id, { content1: content });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <span className='blog-item-hover' onClick={() => setEditModal(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content1 || 'PARAGRAPH'}</span>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const Bullet = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSection(section?.id, { content1: content });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover js-ac mt-1' onClick={() => setEditModal(true)} style={{ color: 'white', cursor: 'pointer' }}>
        <div style={{ width: 4, height: 4, border: '50%', background: 'rgb(92, 125, 109)', borderRadius: '50%', marginRight: 8 }} />{section?.content1 || 'POINT'}
      </div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const Link = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1 || "")
      setHref(section?.href || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSection(section?.id, { content1: content, href: href });
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  const lastP = page?.sections?.find((bs) => bs?.position === section?.position - 1 && bs?.type === 'Paragraph');
  const nextP = page?.sections?.find((bs) => bs?.position === section?.position + 1 && bs?.type === 'Paragraph');
  const hasLast = lastP?.id;
  const hasNext = nextP?.id;
  const margin = hasLast && hasNext ? 'ms-1 me-1' : hasLast ? 'ms-1' : hasNext ? 'me-1' : '';

  return(
    <>
      <span className={`blog-item-hover ${margin}`} onClick={() => setEditModal(true)} style={{ color: 'rgb(92, 125, 109)', cursor: 'pointer' }}>{section?.content1 || 'LINK'}</span>
      {editModal && <LinkModal setEditModal={setEditModal} href={href} content={content} setHref={setHref} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

const ImageText = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let _href = href
        let fileId = await uploadFile(file, file?.type);
        await updateSection(section?.id, { href: _href, content1: fileId });
        let updatedPage = await getPage(page?.id);
        setSelectedPage(updatedPage)
        setLoading(false);
      } catch(err) {
        setLoading(false);
        addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
        <div className='col-12 col-lg-6 pt-2'>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

const TextImage = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let _href = href
        let fileId = await uploadFile(file, file?.type);
        await updateSection(section?.id, { href: _href, content1: fileId });
        let updatedPage = await getPage(page?.id);
        setSelectedPage(updatedPage)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6 pt-2'>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

const TitleTextImage = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal2, setEditModal2] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent2(section?.content2 || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let _href = href
        let fileId = await uploadFile(file, file?.type);
        await updateSection(section?.id, { href: _href, content1: fileId });
        let updatedPage = await getPage(page?.id);
        setSelectedPage(updatedPage)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC2 = () => {
    handleUpdate({ content2: content2 })
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal2(true)} style={{ color: 'white', fontSize: 20, cursor: 'pointer', fontWeight: 600 }}>{section?.content2 || 'SUBTITLE'}</div>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal2 && <ContentModal setEditModal={setEditModal2} content={content2} setContent={setContent2} handleCancel={() => setEditModal2(false)} handleUpdate={handleC2} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

const ImageTitleText = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal2, setEditModal2] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent2(section?.content2 || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let _href = href
        let fileId = await uploadFile(file, file?.type);
        await updateSection(section?.id, { href: _href, content1: fileId });
        let updatedPage = await getPage(page?.id);
        setSelectedPage(updatedPage)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC2 = () => {
    handleUpdate({ content2: content2 })
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
        <div className='col-12 col-lg-6'>
          <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal2(true)} style={{ color: 'white', fontSize: 20, cursor: 'pointer', fontWeight: 600 }}>{section?.content2 || 'SUBTITLE'}</div>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal2 && <ContentModal setEditModal={setEditModal2} content={content2} setContent={setContent2} handleCancel={() => setEditModal2(false)} handleUpdate={handleC2} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

const Video = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 || "https://www.youtube.com/embed/EnGzLlwmjSU?si=ZMYVlRsmJ6XUsvSP";
  const [content, setContent] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const [editModal, setEditModal] = useState(false);
  const width = section?.content2 ? `${section?.content2}px` : "100%";
  const height = section?.content3 ? `${section?.content3}px` : 562;

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1 || "")
      setContent2(section?.content2 || "")
      setContent3(section?.content3 || "")
    }
  }, [])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async (file) => {
    try {
      setLoading(true);
      let updates = {};
      await updateSection(section?.id, updates);
      let updatedPage = await getPage(page?.id)
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Video', type: "ERROR" });
    }
  }

  return(
    <div style={{ width: width, height: height }}>
      <iframe
        className='video mb-2 mt-2'
        title='Youtube player'
        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
        src={`${path}/autoplay=0`}>
      </iframe>
      <div onClick={() => setEditModal(true)} style={{ position: 'absolute', left: -50, top: 10, zIndex: 1000000 }}>
        <IoCogOutline style={{ fontSize: 15, color: 'white' }} />
      </div>
      {editModal && <VideoModal setEditModal={setEditModal} content={content} content2={content2} content3={content3} setContent={setContent} setContent2={setContent2} setContent3={setContent3} handleCancel={handleCancel} handleUpdate={handleUpdate} />}
    </div>
  )
}

const Image = ({ page, section, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleUpdate = async (file) => {
    try {
      setLoading(true);
      let _href = href
      let fileId = await uploadFile(file, file?.type);
      let newFile = await getFile(fileId);
      await updateSection(section?.id, { href: _href, content1: newFile?.filePath });
      let updatedPage = await getPage(page?.id);
      setSelectedPage(updatedPage)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleUpdate} href={href} setHref={setHref} />}
    </>
  )
}

const VideoModal = ({ setEditModal, content, content2, content3, setContent, setContent2, setContent3, handleCancel, handleUpdate }) => {
  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', zIndex: 10000000, padding: 20 }} className='shadow'>
        <input style={{ marginTop: 8, marginBottom: 8, width: 420 }} className='input-styles light-grey-bg mb-3' placeholder='https://youtube.com/' value={content} onChange={(e) => setContent(e.target.value)} />
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='WIDTH i.e. 800 ** OPTIONAL' value={content2} onChange={(e) => setContent2(e.target.value)} />
        <input style={{ marginTop: 0, width: '100%' }} className='input-styles light-grey-bg mb-3' placeholder='Height i.e. 500 ** OPTIONAL' value={content3} onChange={(e) => setContent3(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={() => setEditModal(false)}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={handleUpdate}>SAVE</div>
        </div>
      </div>
    </>
  )
}

const ImageModal = ({ setEditModal, path, handleUpdate, href, setHref }) => {
  const [tempImage, setTempImg] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const _path = displayImage ? displayImage : path;

  const handleImg = (e) => {
    setTempImg(e.target.files[0])
    setDisplayImage(URL.createObjectURL(e.target.files[0]));
  }

  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', zIndex: 10000000, padding: 20 }} className='shadow'>
        <div style={{ position: 'relative', width: 300, border: '1px solid rgb(92, 125, 109)', borderRadius: 6, overflow: 'hidden' }}>
          <input type="file" accept="image/*" multiple={false} style={{ position: 'absolute', left: 0, top: 0, opacity: 0, width: '100%', height: '100%', zIndex: 1 }} onChange={(e) => handleImg(e)} />
          <img style={{ position: 'relative', width: 300, objectFit: 'cover', zIndex: 0 }} alt='' src={_path} />
        </div>
        <input style={{ marginTop: 8, marginBottom: 8, width: '100%' }} className='input-styles light-grey-bg' placeholder='https://apple.com' value={href} onChange={(e) => setHref(e.target.value)} />
        <div className='jb-ac mt-2'>
          <div className='btn1' style={{ width: '49%' }} onClick={() => setEditModal(false)}>CANCEL</div>
          <div className='btn1' style={{ width: '49%' }} onClick={() => handleUpdate(tempImage)}>SAVE</div>
        </div>
      </div>
    </>
  )
}

const Spacer = ({ section }) => {
  let type = section?.sectionType;
  let isLG = type === 'Spacer LG' || type === 'Spacer';
  let isMD = type === 'Spacer MD';
  let height = isLG ? 50 : isMD ? 30 : 12;

  return(
    <div className='blog-item-hover' style={{ width: '100%', height: height }} />
  )
}

const Line = () => {
  return(
    <div className='blog-item-hover mt-3 mb-3' style={{ width: '100%', height: 1, background: 'rgba(255,255,255,.25)' }} />
  )
}

const ProductModal = ({ page, section, editModal, existingProducts, setEditModal, setSelectedPage }) => {
  const { setLoading, addToast } = useApp();
  const [focus, setFocus] = useState(false);
  const [optionProducts, setOptionProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const handleProducts = async () => {
    setProductsLoading(true);
    try {
      let { data } = await getProducts();
      setOptionProducts(data);
      setProductsLoading(false);
    } catch(err) {
      setProductsLoading(false);
    }
  }

  useEffect(() => {
    if (editModal) {
      handleProducts()
    }
  }, [editModal])

  const handleAddProduct = async (p) => {
    try {
      setLoading(true);
      let updatedBlog = await addSectionProduct(section?.id, { productId: p?.id });
      setSelectedPage(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  const handleRemoveProduct =  async (p) => {
    try {
      setLoading(true);
      let updatedBlog = await removeSectionProduct(section?.id, { productId: p?.id });
      setSelectedPage(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, zIndex: 1000000 }} onClick={() => setEditModal(false)} />
      <div style={{ position: 'fixed', left: 'calc(50% + 130px)', top: '50%', transform: 'translate(-50%, -50%)', background: 'black', zIndex: 10000000, padding: 20 }} className='shadow'>
        <div style={{ position: 'relative' }}>
          Add New Product
          <div onClick={() => setFocus(true)} style={{ marginTop: 4, cursor: 'pointer' }} className='input-styles light-grey-bg js-ac'>
            <div style={{ opacity: .4 }}>{productsLoading ? 'PRODUCTS LOADING' : 'CLICK FIND PRODUCTS'}</div>
          </div>
          {focus && (
            <div style={{ position: 'absolute', left: 0, top: 66, width: '100%', background: 'black', border: '1px solid rgba(255,255,255,.12)', borderRadius: 5, maxHeight: 260, overflowY: 'auto', boxShadow: '2px 2px 6px rgba(0,0,0,.2)', zIndex: 100000 }}>
              {optionProducts?.map((p, key) => (
                <div key={key} style={{ width: '100%', height: 46, paddingLeft: 10, color: 'white', cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)' }} className='js-ac' onClick={() => handleAddProduct(p)}>
                  {p?.title}
                </div>
              ))}
            </div>
          )}
        </div>
        {existingProducts?.length > 0 && (
          <div className='mt-3'>
            Existing Products
            {existingProducts?.map((p, key) => (
              <div className='jb-ac mt-1 light-grey-bg p-1' key={key}>
                <div style={{ maxWidth: '90%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{p.title}</div>
                <div onClick={() => handleRemoveProduct(p)}><IoTrashOutline /></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

const Product = ({ page, section, setSelectedPage }) => {
  const [ref, { width }] = useMeasure();
  const [editModal, setEditModal] = useState(false);
  const _products = section?.products;
  const products = _products?.length > 0 ? _products : [{ imageUrl: '/defaultImg.png', title: 'PRODUCT NAME', description: 'PRODUCT DESCRIPTION AND DETAILS AND ALL THAT', price: '$29.99', merchantName: 'OUTSYDE'  }]
  let imgSize = width * .3

  return(
    <>
      <div className='row blog-item-hover mb-2 mt-3' onClick={() => setEditModal(true)} style={{ cursor: 'pointer' }}>
        {products?.map((p, key) => (
          <div ref={ref} key={key} className='col-12 col-lg-6'>
            <div className='light-grey-bg shadow-sm p-2 js-ac' style={{ borderRadius: 6, overflow: 'hidden', width: '100%', minHeight: 100, border: '1px solid rgba(255,255,255,.25)' }}>
              <img alt='' src={p?.imageUrl} style={{ width: imgSize, height: imgSize, borderRadius: 5, marginRight: 10 }} />
              <div style={{ height: imgSize, flexDirection: 'column' }} className='jb-as'>
                <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.2 }}>{p?.title}</div>
                <div className='truncate3' style={{ fontSize: 12, lineHeight: 1.2, color: 'rgb(194,194,194)' }}>{p?.description}...</div>
                <div className='jb-ac' style={{ width: '100%' }}>
                  <div>
                    <div style={{ fontSize: 12, lineHeight: 1 }}>Shop Now</div>
                    <div style={{ fontSize: 14, fontWeight: 800, color: 'rgb(92, 125, 109)', lineHeight: 1 }}>{p?.merchantName}</div>
                  </div>
                  <div className='je-ac'>
                    <div className='btn1' style={{ paddingLeft: 16, paddingRight: 16, height: 34 }}>{p?.price}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {editModal && <ProductModal page={page} editModal={editModal} setEditModal={setEditModal} section={section} existingProducts={_products} setSelectedPage={setSelectedPage} />}
    </>
  )
}

// const ProductSearch = ({ handleAddProduct }) => {
//   const [focus, setFocus] = useState(false);
//   const [products, setProducts] = useState([])
//
//   const handleProducts = async () => {
//     try {
//       let { data } = await getProducts();
//       setProducts(data)
//     } catch(err) {
//       console.log(err);
//     }
//   }
//
//   useEffect(() => {
//     if (focus) {
//       handleProducts()
//     }
//   }, [focus])
//
//   return(
//     <div style={{ position: 'relative' }}>
//       <div onClick={() => setFocus(true)} className='input-styles light-grey-bg' placeholder='SELECT PRODUCT'>
//       </div>
//       {focus && (
//         <div style={{ position: 'absolute', left: 0, top: 60, width: '100%', background: 'black', border: '1px solid rgba(255,255,255,.12)', borderRadius: 5, maxHeight: 260, overflowY: 'auto', boxShadow: '2px 2px 6px rgba(0,0,0,.2)', zIndex: 100000 }}>
//           {products?.map((p, key) => (
//             <div key={key} style={{ width: '100%', height: 46, paddingLeft: 10, color: 'white', cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)' }} className='js-ac' onClick={() => handleAddProduct(p)}>
//               {p?.title}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   )
// }

export const selectStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      background: 'rgba(0,0,0,0)',
      color: 'rgba(0,0,0,0)',
      opacity: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color:'rgba(0,0,0,0)',
      opacity: 0
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100000001,
      minHeight: 50,
      // background: 'rgba(0,0,0,0)',
      // width: 'calc(100% + 6px)',
      marginLeft: -3
    }),
    container: (provided, state) => ({
      ...provided,
      width: '360px',
    }),
    control: (provided, state) => ({
      ...provided,
      height: 46,
      flexShrink: 0,
      width: '100%',
      background:'rgba(54,54,54,1)',
      border: (state.isFocused || state.isActive || state.isHovered) ? "2px solid rgb(92, 125, 109)" : "2px solid rgb(92, 125, 109)",
      borderRadius: 5,
      boxShadow: (state.isFocused || state.isActive) ? 0 : 0,
      paddingLeft: 0,
      color: 'white',
      fontSize: 15,
      fontWeight: 400,
      paddingTop: 2,
      outline: 'none',
      marginTop: 14,
    }),
    input: (provided) => ({
      ...provided,
      color: '#ffffff',
      fontSize: 15,
      fontWeight: 400,
      // fontFamily: 'Gilroy-Regular'
    }),
    option: (provided, state) => ({
      ...provided,
      color: (state.isSelected) ? 'white' : 'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400,
      backgroundColor: state.isSelected ? "#46C695" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
      fontSize: 15,
      fontWeight: 400,
    }),
  }
