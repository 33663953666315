import { useApp } from 'context';
import { getUsers } from 'api/users';
import UserIcon from 'components/UserIcon'
import { useState, useEffect } from 'react';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

export const UserSearch = ({ blog, open, onChange }) => {
  const { setLoading, addToast } = useApp();
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const svl = searchValue?.length;
  const showDropdown = svl > 0;

  useEffect(() => {
    if (open) {
      setSearchValue(blog?.user?.username);
    }
    //eslint-disable-next-line
  }, [open])

  const handleSelect = (user) => {
    onChange(user);
    setSearchValue(user?.username)
    setUsers([])
  }

  const handleUserSearch = async (e) => {
    try {
      let newVal = e.target.value;
      setSearchValue(newVal);
      if (newVal?.length > 0) {
        let data = await getUsers({ filter: { username: { _iLike: `%25${newVal}%25` } } });
        setUsers(data);
      } else {
        setUsers([]);
      }
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error searching users', type: "ERROR" });
    }
  }

  return(
    <div style={{ position: 'relative' }}>
      <input className='input-styles light-grey-bg' placeholder='SEARCH FOR AUTHOR' value={searchValue || ""} onChange={(e) => handleUserSearch(e)} />
      {showDropdown && (
        <div style={{ position: 'absolute', left: 0, top: 60, width: '100%', background: 'black', border: '1px solid rgba(255,255,255,.12)', borderRadius: 5, maxHeight: 260, overflowY: 'auto', boxShadow: '2px 2px 6px rgba(0,0,0,.2)', zIndex: 100000 }}>
          {users?.map((user, key) => (
            <div key={key} style={{ width: '100%', height: 46, paddingLeft: 10, color: 'white', cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)' }} className='js-ac' onClick={() => handleSelect(user)}>
              <UserIcon user={user} style={{ marginRight: 8, width: 20, height: 20, fontSize: 12 }} />
              {user?.username}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
