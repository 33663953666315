import { logout } from '../api/auth';
//<HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='4 Square' value='4square' />
//        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='4 Square' value='4square' />
const Menu = ({ setDashPage, dashPage }) => {
  return(
    <div style={{ height: '100vh', width: 260, background: 'black', position: 'relative' }} className='flex-col jb-as shadow'>
      <div style={{ width: '100%' }}>
        <img alt='' src='/logoText.png' style={{ width: 140, margin: 20 }} />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='User Applications' value='applications' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Users' value='users' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Reported Posts' value='reports' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Stores' value='stores' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Activities' value='activities' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Locations' value='locations' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Location Types' value='locationTypes' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Blog' value='blogs' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Pages' value='docs' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Outreach' value='outreach' />
        <HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Contact Form' value='contactFrom' />
      </div>
      <div onClick={logout} className='header-nav-btn' style={{ width: '100%', marginBottom: 20 }}>
        Logout
      </div>
    </div>
  )
}

//<HeaderNavBtn setDashPage={setDashPage} dashPage={dashPage} label='Blog' value='blogs' />

export default Menu;

const HeaderNavBtn = ({ label, value, dashPage, setDashPage }) => {
  let selected = value === dashPage;

  return(
    <div
      className='header-nav-btn hover-item'
      onClick={() => setDashPage(value)}
      style={{ background: selected ? '#5c7d6d' : '' }}
    >
      {label}
    </div>
  )
}
