import { client } from './client';

export const sendUpdateEmail = async () => {
  let { data } = await client.post(`emails/new-update`);
  return data;
}

export const sendFeatureEmail = async () => {
  let { data } = await client.post(`emails/new-feature`);
  return data;
}

export const sendFeedbackEmail = async () => {
  let { data } = await client.post(`emails/feedback-request`);
  return data;
}

export const sendMissYouEmail = async () => {
  let { data } = await client.post(`emails/miss-you`);
  return data;
}

export const sendDynamicEmail = async (values) => {
  let { data } = await client.post(`emails/dynamic`, values);
  return data;
}
