import { client } from './client';

export const getLocationTypes = async () => {
  try {
    let response = await client.get(`location-types`);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
}

export const editLocationType = async (id, body) => {
  try {
    let response = await client.put(`location-types/${id}`, body);
    return response;
  } catch(err) {
    console.error('API ERROR')
  }
}

export const setLocationTypeActivities = async (id, body) => {
  try {
    let response = await client.put(`set-location-type-activities/${id}`, body);
    return response;
  } catch(err) {
    console.error('API ERROR')
  }
}
