// External Imports
import Toast from "react-bootstrap/Toast";
import { IoCheckmark, IoAlert } from "react-icons/io5";
import ToastContainer from "react-bootstrap/ToastContainer";

// Internal Imports
import { useApp } from "context";

function AdminToasts() {
  const { toasts, setToasts } = useApp();

  const handleClose = (id) => {
    let remaining = toasts?.filter((t) => t.id !== id);
    setToasts(remaining);
  };

  return (
    <ToastContainer position="top-end" style={{ padding: 16, color: 'black' }}>
      {toasts.map((toast, key) => (
        <Toast
          key={key}
          show={true}
          onClose={() => handleClose(toast?.id)}
          delay={toast?.delay}
          autohide={toast.autohide}
        >
          <Toast.Header>
            {toast?.type === "ERROR" ? (
              <IoAlert style={{ color: "red", marginRight: 6 }} />
            ) : (
              <IoCheckmark style={{ color: "green", marginRight: 6 }} />
            )}
            <strong className="me-auto">{toast?.title}</strong>
          </Toast.Header>
          <Toast.Body>{toast?.body}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
}

export default AdminToasts;
