import { useApp } from 'context';
import Checkbox from 'components/Checkbox';
import { useState, useEffect } from 'react';
import { getStores, editStore, addStore } from 'api/sovrn';

// const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Stores = () => {
  const { setLoading, addToast } = useApp();
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState({});
  const [addStoreModal, setAddStoreModal] = useState(false);
  const sorted = stores?.sort((a, b) => a?.name.localeCompare(b?.name))
  // console.log(stores);
  const handleStores = async () => {
    try {
      setLoading(true);
      let { data } = await getStores();
      let sortedStores = data?.sort((a, b) => a.id - b.id)
      setStores(sortedStores);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error getting stores', type: "ERROR" });
    }
  }

  useEffect(() => {
    handleStores()
  //eslint-disable-next-line
  }, [])

  const handleEdit = async (id, changes) => {
    try {
      await editStore(id, changes);
      await handleStores();
    } catch (err) {
      addToast({ title: "Error", body: 'Error editing store', type: "ERROR" });
    }
  }

  return(
    <div className='p-3'>
      <div className='jb-ac mb-3'>
        <div style={{ fontSize: 18, fontWeight: 700 }}>MERCHANTS</div>
        <div className='btn1' style={{ paddingLeft: 22, paddingRight: 22, width: 200 }} onClick={() => setAddStoreModal(true)}>ADD MERCHANT</div>
      </div>
      {sorted?.map((store, key) => (
        <div key={key} style={{ width: '100%', padding: '20px 0px', color: 'white', borderBottom: '1px solid rgba(255,255,255,.25)' }} className='d-flex justify-content-between align-items-center'>
          <div className='js-ac'>
            <Checkbox value={store?.active} setValue={(newValue) => handleEdit(store?.id, { active: newValue })} />
            <div onClick={() => setSelectedStore(store)} className='ms-3'>{store?.name}</div>
          </div>
          <div className='js-ac'>
            <div>{store.averageCPA}%</div>
            <div className='ms-3'>${store.averageCPC}</div>
          </div>
        </div>
      ))}
      <AddStoreModal open={addStoreModal} setOpen={setAddStoreModal} handleStores={handleStores} />
      <EditStoreModal selectedStore={selectedStore} setSelectedStore={setSelectedStore} handleStores={handleStores} />
    </div>
  )
}

export default Stores;

 // setValue={(newValue) => handleEdit(activity?.id, { active: newValue })} />

 const EditStoreModal = ({ selectedStore, setSelectedStore, handleStores }) => {
   const { setLoading, addToast } = useApp();
   const [hasChanges, setHasChanges] = useState({});
   const dataUpdates = Object.keys(hasChanges)?.length > 0;
   const enable = dataUpdates;

   const handleLocUpdate = (type, value) => {
     setHasChanges({ ...hasChanges, type })
     setSelectedStore({...selectedStore, [type]: value })
   }

   const handleEdit = async () => {
     if (enable) {
       try {
         setLoading(true);
         const changes = {
           name: selectedStore?.name,
           averageCPA: selectedStore?.averageCPA,
           averageCPC: selectedStore?.averageCPC,
           active: selectedStore?.active
         }
         await editStore(selectedStore?.id, changes);
         await handleStores();
         setSelectedStore({});
         setHasChanges({});
         setLoading(false);
       } catch (err) {
         setLoading(false);
         addToast({ title: "Error", body: 'Error editing store', type: "ERROR" });
       }
     }
   }

   if (selectedStore?.id) {
     return(
       <div style={{ position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer' }}>
         <div style={{ position: 'fixed', right: 20, top: 20, color: 'white' }} onClick={() => setSelectedStore({})}>X</div>
         <div className='d-flex align-items-center' style={{ flexDirection: 'column', marginTop: 60, paddingBottom: 100 }}>

           <input style={inputStyles} placeholder='NAME' value={selectedStore?.name || ''} onChange={(e) => handleLocUpdate('name', e.target.value)} />
           <input disabled={true} style={{ ...inputStyles, opacity: 0.5 }} placeholder='DOMAIN' value={selectedStore?.domain || ''} onChange={(e) => handleLocUpdate('domain', e.target.value)} />
           <input style={inputStyles} placeholder='AVERAGE CPA' value={selectedStore?.averageCPA || ''} onChange={(e) => handleLocUpdate('averageCPA', e.target.value)} />
           <input style={inputStyles} placeholder='AVERAGE CPC' value={selectedStore?.averageCPC || ''} onChange={(e) => handleLocUpdate('averageCPC', e.target.value)} />
           <div className='jb-ac mt-3 mb-5' style={{ width: 360 }}>
              <div>ACTIVE</div>
              <Checkbox value={selectedStore?.active} setValue={(newValue) => handleLocUpdate('active', newValue)} />
           </div>
           <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d', opacity: enable ? 1 : .5 }} onClick={handleEdit}>EDIT</div>
           <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d' }} onClick={() => setSelectedStore({})}>CANCEL</div>

         </div>
       </div>
     )
   } else {
     return null;
   }
 }

 const inputStyles = { width: 360, background: 'transparent', border: '2px solid #5c7d6d', height: 46, paddingLeft: 10, borderRadius: 5, outline: 'none', color: 'white', marginTop: 14 }

 const AddStoreModal = ({ open, setOpen, handleStores }) => {
   const { setLoading, addToast } = useApp();
   const [newStore, setNewStore] = useState({});

   useEffect(() => {
     if (open) {
       setNewStore({})
     }
   }, [open])

   const handleLocUpdate = (type, value) => {
     setNewStore({...newStore, [type]: value })
   }

   const handleAdd = async () => {
     if (!newStore?.name) {
       addToast({ title: "Error", body: 'PLEASE ADD A NAME', type: "ERROR" });
     } else if (!newStore?.domain) {
       addToast({ title: "Error", body: 'PLEASE ADD A DOMAIN', type: "ERROR" });
     } else if (!newStore?.averageCPA) {
       addToast({ title: "Error", body: 'PLEASE ADD A CPA', type: "ERROR" });
     } else if (!newStore?.averageCPC) {
       addToast({ title: "Error", body: 'PLEASE ADD A CPC', type: "ERROR" });
     } else {
       try {
         setLoading(true);
         await addStore(newStore);
         await handleStores();
         setOpen(false)
         setLoading(false);
       } catch (err) {
         setLoading(false);
         addToast({ title: "Error", body: 'Error creating store', type: "ERROR" });
       }
     }
   }

   if (open) {
     return(
       <div style={{ position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer' }}>
         <div style={{ position: 'fixed', right: 20, top: 20, color: 'white' }} onClick={() => setOpen(false)}>X</div>
         <div className='d-flex align-items-center' style={{ flexDirection: 'column', marginTop: 60, paddingBottom: 100 }}>
           <input style={inputStyles} placeholder='NAME' value={newStore?.name || ''} onChange={(e) => handleLocUpdate('name', e.target.value )} />
           <input style={inputStyles} placeholder='DOMAIN' value={newStore?.domain || ''} onChange={(e) => handleLocUpdate('domain', e.target.value)} />
           <input style={inputStyles} placeholder='AVERAGE CPA' value={newStore?.averageCPA || ''} onChange={(e) => handleLocUpdate('averageCPA', e.target.value)} />
           <input style={inputStyles} placeholder='AVERAGE CPC' value={newStore?.averageCPC || ''} onChange={(e) => handleLocUpdate('averageCPC', e.target.value)} />
           <div className='jb-ac mt-3 mb-5' style={{ width: 360 }}>
              <div>ACTIVE</div>
              <Checkbox value={newStore?.active} setValue={(newValue) => handleLocUpdate('active', newValue)} />
           </div>
           <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d' }} onClick={handleAdd}>ADD</div>
           <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d' }} onClick={() => setOpen(false)}>CANCEL</div>

         </div>
       </div>
     )
   } else {
     return null;
   }
 }
