import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import outsyde from "outsyde-sdk";
//
// outsyde.initialize({
//   cognitoUserPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
//   cognitoClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
//   cognitoRegion: process.env.REACT_APP_AWS_COGNITO_REGION,
//   apiBaseUrl: process.env.REACT_APP_BASE_API_ROUTE,
//   apiVersion: "0",
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
