import { client } from './client';
import { Auth } from "aws-amplify";

export const getCurrent = async () => {
  try {
    await client.get("users/current");
  } catch(err) {
    console.error("API ERROR")
  }
};

export const getCode = async (data) => {
  try {
    let response = await client.post("admin/get-code", data);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
};

export const confirmCode = async (data) => {
  try {
    let response = await client.post("admin/confirm-code", data);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
};

export const login = async (username, password) => {
  try {
    let user = await Auth.signIn(username, password);
    let response = await client.post("auth/login");
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
};

export const logout = () => {
  window.location.href = "/login";
  Auth.signOut()
};
