//External Imports
import { useApp } from 'context';
import { useEffect, useState } from 'react';

//Internal Imports
import { sendDynamicEmail } from 'api/email';
import { getPendingCreators, approveCreator, denyCreator } from 'api/pending';
// import { getPendingProviders, approveProvider, denyProvider } from 'api/pendingCompanies';

const Applications = () => {
  const { setLoading, addToast } = useApp();
  const [pending, setPending] = useState([]);

  const handleCreators = async () => {
    try {
      setLoading(true);
      let data = await getPendingCreators();
      setPending(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error getting applications', type: "ERROR" });
    }
  }

  useEffect(() => {
    handleCreators();
  //eslint-disable-next-line
  }, [])

  const handleApprove = async (id) => {
    try {
      setLoading(true);
      await approveCreator(id);
      await sendDynamicEmail({ userId: id, templateId: "d-18138b0abc394f8db62b5273b8ce0d6d  " });
      handleCreators()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error approving application', type: "ERROR" });
    }
  }

  const handleDeny = async (id) => {
    try {
      setLoading(true);
      await denyCreator(id);
      await sendDynamicEmail({ userId: id, templateId: "d-0d8bc18636844b3fa26b2dc0e5751509" });
      handleCreators()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error denying application', type: "ERROR" });
    }
  }

  return(
    <div style={{ width: '100%', height: '100%', fontSize: 14, padding: 20 }}>
      {pending?.length > 0 && (
        <div style={{ border: '1px solid rgb(255,255,255,.2)', overflow: 'hidden' }} className='shadow'>
          {pending?.length > 0 && <TableHeader />}
          {pending?.map((account, key) => (
            <div className='jb-ac' key={key} style={{ background: 'rgb(4,4,4)', flexShrink: 0, borderTop: '1px solid rgb(255,255,255,.2)', width: '100%', height: 52, paddingLeft: 20, paddingRight: 20, color: 'white', fontWeight: 600 }}>
              <div className='js-ac'>
                <div style={{ width: 150 }}>{account?.username}</div>
                <div style={{ width: 350 }}>{account?.bio}</div>
                <div style={{ width: 150 }}>{account?.tiktok}</div>
                <div style={{ width: 150 }}>{account?.instagram}</div>
              </div>
              <div className='js-ac'>
                <div className='btn1' style={{ marginRight: 20, height: 32, paddingLeft: 12, paddingRight: 12 }} onClick={() => handleApprove(account?.id)}>APPROVE</div>
                <div className='btn2' style={{ height: 32, paddingLeft: 12, paddingRight: 12 }} onClick={() => handleDeny(account?.id)}>DENY</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {pending?.length === 0 && (
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white' }}>
          No Pending Applications
        </div>
      )}

    </div>
  )
}

export default Applications;

const TableHeader = () => {
  return(
    <div className='jb-ac' style={{ background: '#5c7d6d', width: '100%', height: 30, paddingLeft: 20, paddingRight: 20, color: 'white', fontWeight: 600 }}>
      <div className='js-ac'>
        <div style={{ width: 150 }}>USERNAME</div>
        <div style={{ width: 350 }}>BIO</div>
        <div style={{ width: 150 }}>TIKTOK</div>
        <div style={{ width: 150 }}>INSTAGRAM</div>
      </div>
    </div>
  )
}
