import { useApp } from 'context';
import {
  getBlog,
  addSection,
  updateSection,
  removeSection,
} from 'api/blog';
import { IoTrashOutline, IoArrowUp, IoArrowDown } from 'react-icons/io5';
import { BsX } from 'react-icons/bs';

export const BlogSectionsModal = ({ blog, open, setOpen, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  let sections = blog?.sections
  let sortedSections = sections?.sort((a, b) => a.position - b.position)
  let formattedSections = sortedSections?.map((s, idx) => ({ ...s, idx: idx + 1 }))
  let sectionCount = formattedSections?.length;

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddSection = async (type) => {
    setLoading(true)
    try {
      await addSection({ postId: blog?.id, sectionType: type, position: sectionCount + 1 });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }

  const handleRemoveSection = async (section) => {
    setLoading(true)
    try {
      await removeSection(section?.id);
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false)
    } catch(err) {
      setLoading(false)
    }
  }

  const handleMoveUp = async (bs) => {
    if (bs?.position > 1) {
      try {
        setLoading(true)
        let updatedBlog = await updateSection(bs?.id, { position: bs?.position - 1 });
        setSelectedBlog(updatedBlog)
        setLoading(false)
      } catch(err) {
        addToast({ title: "Error", body: 'Error moving section', type: "ERROR" })
        setLoading(false)
      }
    }
  }

  const handleMoveDown = async (bs) => {
    if (bs?.position !== sectionCount) {
      try {
        setLoading(true)
        let updatedBlog = await updateSection(bs?.id, { position: bs?.position + 1 });
        setSelectedBlog(updatedBlog)
        setLoading(false)
      } catch(err) {
        addToast({ title: "Error", body: 'Error moving section', type: "ERROR" })
        setLoading(false)
      }
    }
  }

  if (open) {
    return(
      <>
        <div style={{ position: 'fixed', width: 400, height: '100vh', right: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer', padding: 17.5, overflowY: 'auto' }}>
          <div className='jb-ac' style={{ color: 'white', padding: '15px 2.5px 10px 2.5px', position: 'fixed', width: 370, background: 'black', top: 0 }}>
            <div style={{ fontSize: 20, fontWeight: 600 }}>BLOG COMPONENTS</div>
            <div onClick={handleClose}><BsX style={{ fontSize: 24 }} /></div>
          </div>
          <div style={{ height: 40 }} />
          <div className='js-ac' style={{ flexWrap: 'wrap' }}>
            {sectionTypes?.map((s, key) => (
              <div onClick={() => handleAddSection(s?.type)} key={key} style={{ width: 86, height: 60, marginLeft: 2.5, marginRight: 2.5, fontSize: 12 }} className='light-grey-bg jc-ac mb-1 blog-item-hover'>
                {s?.type}
              </div>
            ))}
          </div>
          <div style={{ margin: 2.5, paddingTop: 5 }}>
            <div>SECTIONS</div>
            {formattedSections?.map((bs, idx) => (
              <div style={{ width: '100%' }} className='ps-2 pe-2 pt-1 pb-1 light-grey-bg mt-2 jb-ac blog-item-hover' key={idx}>
                <div>{bs?.type}</div>
                <div className='js-ac'>
                  <div className='me-2' onClick={() => handleMoveUp(bs)}><IoArrowUp /></div>
                  <div className='me-2' onClick={() => handleMoveDown(bs)}><IoArrowDown /></div>
                  <div onClick={() => handleRemoveSection(bs)}><IoTrashOutline /></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  } else {
    return null;
  }
}

const sectionTypes = [
  { type: 'Title' },
  { type: 'Subtitle' },
  { type: 'SubSubtitle' },
  { type: 'Paragraph' },
  { type: 'Image' },
  { type: 'Link' },
  { type: 'Product' },
  { type: 'Line' },
  { type: 'ImageTitleText' },
  { type: 'TitleTextImage' },
  { type: 'ImageText' },
  { type: 'TextImage' },
  { type: 'Spacer LG' },
  { type: 'Spacer MD' },
  { type: 'Spacer SM' },
  { type: 'Video' },
  { type: 'Embed' },
  { type: 'Bullet' },
]
