import { BsCheck } from 'react-icons/bs';
import { useState } from 'react';

const Checkbox = ({ value, setValue }) => {
  const [localValue, setLocalValue] = useState(value);

  const handleChange = () => {
    setLocalValue(!localValue);
    setValue(!localValue)
  }

  return(
    <div style={{ width: 20, height: 20, border: localValue ? '' : '1px solid white', background: localValue ? '#5c7d6d' : '' }} onClick={handleChange} className='jc-ac'>
      {localValue && <BsCheck style={{ fontSize: 18, color: 'white' }} />}
    </div>
  )
}

export default Checkbox;

//5c7d6d
//479761
