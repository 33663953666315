import { client } from './client';
import { addUrlQuery } from './utils';

export const getPendingCreators = async () => {
  const url = addUrlQuery(`/users`, { filter: { isPro: { _eq: "pending" } } });
  const { data } = await client.get(url);
  return data;
}

export const approveCreator = async (id) => {
  const updates = { isPro: "true" };
  const { data } = await client.put(`/users/${id}`, updates);
  return data;
}

export const denyCreator = async (id) => {
  const updates = { isPro: "false" };
  const { data } = await client.put(`/users/${id}`, updates);
  return data;
}
