import { editLocationType, setLocationTypeActivities } from 'api/locationTypes';
import { useState, useEffect } from "react";
import Checkbox from 'components/Checkbox';
import { uploadFile } from 'api/uploader';
import { useApp } from 'context';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const LocationTypes = () => {
  const { setLoading, addToast, locationTypes, handleLocationTypes } = useApp();
  const [selectedType, setSelectedType] = useState({});
  const sorted = locationTypes?.sort((a, b) => a.name.localeCompare(b.name));

  const handleEdit = async (id, changes) => {
    try {
      setLoading(true);
      await editLocationType(id, changes);
      handleLocationTypes();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error editing location type', type: "ERROR" });
    }
  }

  const handleIcon = async (e, id) => {
    try {
      setLoading(true);
      let file = e.target.files[0];
      let fileId = await uploadFile(file, file?.type);
      editLocationType(id, { iconId: fileId })
      handleLocationTypes()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error uploading icon', type: "ERROR" });
    }
  }

  return(
    <div className='p-4'>
      {sorted?.map((type, key) => (
        <div key={key} style={{ width: '100%', padding: '20px 0px', color: 'white', borderBottom: '1px solid rgba(255,255,255,.25)' }} className='d-flex justify-content-between align-items-center'>
          <div>
            <div className='d-flex justify-content-start'>
              <Checkbox value={type?.active} setValue={(newValue) => handleEdit(type?.id, { active: newValue })} />
              <div className='ms-3'>{type.name}</div>
            </div>
            <div className='text-hover' onClick={() => setSelectedType(type)} style={{ fontSize: 12, color: 'rgba(255,255,255,.75)', marginTop: 5 }}>
              {type?.activities?.map((act, index) => <span key={index}>{act?.name}{index === type?.activities?.length - 1 ? '' : ', '}</span>)}
              {type?.activities?.length === 0 && <span>None</span>}
            </div>
          </div>
          <div style={{ position: 'relative', width: 50, height: 50, background: 'rgb(79, 115, 97)' }}>
            <input type="file" accept="image/*" multiple={false} style={{ position: 'absolute', left: 0, top: 0, opacity: 0, width: '100%', height: '100%', zIndex: 1 }} onChange={(e) => handleIcon(e, type?.id)} />
            {type?.icon && <img style={{ position: 'relative', width: 50, height: 50, objectFit: 'contain', zIndex: 0 }} alt='' src={`${CDN_ROUTE}${type?.icon?.filePath}`} />}
          </div>
        </div>
      ))}
      {selectedType?.id && <TypeActivityModal selectedType={selectedType} setSelectedType={setSelectedType} handleLocationTypes={handleLocationTypes} />}
    </div>
  )
}

export default LocationTypes;

const TypeActivityModal = ({ selectedType, setSelectedType, handleLocationTypes }) => {
  const open = selectedType?.id;
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedActs, setSelectedActs] = useState([]);
  const { activities, setLoading, addToast, handleActivities } = useApp();
  const sorted = activities?.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (open) {
      handleActivities();
    }
  }, [open, handleActivities])

  const isSelected = (act) => {
    let selected = selectedActs?.includes(act?.id);
    return selected
  }

  const handleEdit = (newValue, actId) => {
    if (!hasChanges) {
      setHasChanges(true);
    }

    if (newValue) {
      setSelectedActs([...selectedActs, actId])
    } else {
      let remaining = selectedActs?.filter((sactId) => sactId !== actId);
      setSelectedActs(remaining);
    }
  }

  let selectedTypeActivities = selectedType?.activities;

  useEffect(() => {
    if (open) {
      let formatted = selectedTypeActivities?.map((sact) => sact?.id) || [];
      setSelectedActs(formatted)
    }
  }, [open, selectedTypeActivities])

  const handleClose = () => {
    setSelectedType({})
  }

  const handleLocationTypeActivities = async () => {
    try {
      setLoading(true);
      await setLocationTypeActivities(selectedType?.id, { activityIds: selectedActs });
      await handleLocationTypes();
      handleClose();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error updating connected activities', type: "ERROR" });
    }
  }

  const handleDone = () => {
    if (hasChanges) {
      handleLocationTypeActivities()
    }
  }

  if (open) {
    return(
      <div style={{ position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, background: '#19181a', overflow: 'auto', cursor: 'pointer' }}>
        <div style={{ position: 'fixed', right: 20, top: 20, color: 'white' }} onClick={handleClose}>X</div>
        <div className='d-flex align-items-center' style={{ flexDirection: 'column', marginTop: 20, paddingBottom: 100 }}>
          {sorted?.map((act, key) => (
            <div className='d-flex justify-content-start align-items-center' key={key} style={{ width: 300, height: 40 }}>
              <Checkbox value={isSelected(act)} key={selectedActs?.length} setValue={(newValue) => handleEdit(newValue, act?.id)} />
              <div className='ms-3'>{act.name}</div>
            </div>
          ))}
        </div>
        <div style={{ width: '100%', height: 70, background: 'black', position: 'fixed', left: 0, bottom: 0 }} className='jc-ac shadow'>
          <div className='btn3' onClick={handleClose} style={{ marginLeft: 10, marginRight: 10, maxWidth: 180 }}>CANCEL</div>
          <div className='btn1' disabled={!hasChanges} onClick={handleDone} style={{ marginLeft: 10, marginRight: 10, maxWidth: 180, opacity: hasChanges ? 1 : 0.5 }}>UPDATE</div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}
