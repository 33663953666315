import { useApp } from 'context';
import { UserSearch } from './components';
import { useState, useEffect } from 'react';
import GeoSearch from 'components/GeoSearch';
import {
  getBlog,
  updateBlog,
  deleteBlog,
  uploadBlogImage,
  updateBlogCoverPhoto,
} from 'api/blog';
import Select from 'react-select';
import { BsX } from 'react-icons/bs';
import { deleteFile } from 'api/files';
import { uploadFile } from 'api/uploader';
import { BlogCover } from 'pages/DashScreens/Blogs';
const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

export const BlogSettingsModal = ({ blog, open, setOpen, setSelectedBlog }) => {
  const { setLoading, addToast, activities, postTypes } = useApp();
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [desc, setDesc] = useState('');
  const [act, setAct] = useState({ value: 0, label: 'Choose Activity' });
  const mainFile = blog?.files?.find((f) => f?.fileType === 'main');
  const path = `${CDN_ROUTE}${mainFile?.filePath}`
  const _options = activities?.map((act) => ({ ...act, value: act.id, label: act.name }));
  const options = _options?.sort((a, b) => a.label.localeCompare(b.label));

  const handleUser = async (newValue) => {
    handleBlogUpdate({ userId: newValue?.id })
  }

  const handleAct = (newValue) => {
    setAct(newValue)
    handleBlogUpdate({ activityId: newValue?.id })
  }

  const handleBlogUpdate = async (updates) => {
    setLoading(true);
    try {
      await updateBlog(blog?.id, updates);
      const updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Blog', type: "ERROR" });
    }
  }

  const handlePublished = () => {
    handleBlogUpdate({ published: !blog?.published })
  }

  const handleTitle = () => {
    if (title !== blog?.name) {
      handleBlogUpdate({ name: title })
    }
  }

  const handleSlug = () => {
    if (slug !== blog?.slug) {
      handleBlogUpdate({ slug: slug })
    }
  }

  const handleDesc = () => {
    if (desc !== blog?.description) {
      handleBlogUpdate({ caption: desc })
    }
  }

  const handleBlogType = (newType) => {
    if (newType !== blog?.postTypeId) {
      handleBlogUpdate({ postTypeId: newType })
    }
  }

  const handleAddress = (newAddress) => {
    let newAddressId = newAddress?.id;

    if (newAddressId !== blog?.locationId) {
      handleBlogUpdate({ locationId: newAddressId })
    }
  }

  const handleBlogImg = async (e) => {
    try {
      setLoading(true);
      let file = e.target.files[0];
      let fileId = await uploadFile(file, "main");
      await updateBlogCoverPhoto(blog?.id, fileId)
      const updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  useEffect(() => {
    if (open) {
      setTitle(blog?.name || '');
      setSlug(blog?.slug || '');
      setDesc(blog?.caption || '');

      let _act = options?.find((op) => op?.id === blog?.activityId);
      if (_act?.id) {
        setAct(_act)
      }
    }
    //eslint-disable-next-line
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    try {
      setSelectedBlog({})
      await deleteBlog(blog?.id);
    } catch(err) {
      console.log(err);
    }
  }

  if (open) {
    return(
      <>
        <div style={{ position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0 }} onClick={handleClose} />
        <div style={{ position: 'fixed', width: 400, height: '100vh', right: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer', padding: 20 }}>
          <div className='jb-ac' style={{ color: 'white', padding: '15px 2.5px 10px 2.5px', position: 'fixed', width: 370, background: 'black', top: 0 }}>
            <div style={{ fontSize: 20, fontWeight: 600 }}>BLOG OVERVIEW</div>
            <div onClick={handleClose}><BsX style={{ fontSize: 24 }} /></div>
          </div>
          <div style={{ height: 40 }} />
          <div className='d-flex align-items-center' style={{ flexDirection: 'column', paddingBottom: 20 }}>
            <div className='jb-ac mb-2' style={{ width: '100%' }}>
              <div>Publised</div>
              <div onClick={handlePublished} style={{ height: 30, width: 50, padding: 2, position: 'relative', borderRadius: 20 }} className='light-grey-bg'>
                <div style={{ width: 26, height: 26, borderRadius: '50%', background: blog?.published ? 'rgb(92, 125, 109)' : 'white', marginLeft: blog?.published ? 20 : 0 }} />
              </div>
            </div>
            <input onBlur={handleTitle} style={{ marginTop: 0 }} className='input-styles light-grey-bg' placeholder='NAME' value={title} onChange={(e) => setTitle(e.target.value)} />
            <input onBlur={handleSlug} className='input-styles light-grey-bg' placeholder='SLUG' value={slug} onChange={(e) => setSlug(e.target.value)} />
            <textarea onBlur={handleDesc} style={{ height: 120 }} className='input-styles light-grey-bg' placeholder='DESCRIPTION' value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
            <Select
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isSearchable={true}
              name="Activity"
              options={options}
              styles={selectStyles}
              placeholder='Activity'
              value={act}
              onChange={(newValue) => handleAct(newValue)}
            />
            <UserSearch blog={blog} open={open} onChange={handleUser} />
            <select className='input-styles light-grey-bg' value={blog?.postTypeId || ''} onChange={(e) => handleBlogType(e.target.value)} placeholder='Blog Type'>
              <option value=''>Blog Type</option>
              {postTypes?.map((pt) => (
                <option value={pt?.id} key={pt?.id}>{pt?.name}</option>
              ))}
            </select>
            <GeoSearch value={blog?.location} onChange={handleAddress} />
            <div className='js-ac mt-3 mb-1' style={{ width: '100%' }}>Cover Photo/Video</div>
            <div style={{ position: 'relative', width: '100%', border: '1px solid rgb(92, 125, 109)' }}>
              <input type="file" accept="image/*, video/*" multiple={false} style={{ position: 'absolute', left: 0, top: 0, opacity: 0, width: '100%', height: '100%', zIndex: 1 }} onChange={(e) => handleBlogImg(e)} />
              <BlogCover blog={blog} contentHeight={"100%"} postWidth={"100%"} />
            </div>
            <div onClick={handleDelete} className='mt-3 btn1' style={{ background: 'red' }}>DELETE POST</div>
          </div>
        </div>
      </>
    )
  } else {
    return null;
  }
}

export const selectStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      background: 'rgba(0,0,0,0)',
      color: 'rgba(0,0,0,0)',
      opacity: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color:'rgba(0,0,0,0)',
      opacity: 0
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100000001,
      minHeight: 50,
      // background: 'rgba(0,0,0,0)',
      // width: 'calc(100% + 6px)',
      marginLeft: -3
    }),
    container: (provided, state) => ({
      ...provided,
      width: '360px',
    }),
    control: (provided, state) => ({
      ...provided,
      height: 46,
      flexShrink: 0,
      width: '100%',
      background:'rgba(54,54,54,1)',
      border: (state.isFocused || state.isActive || state.isHovered) ? "2px solid rgb(92, 125, 109)" : "2px solid rgb(92, 125, 109)",
      borderRadius: 5,
      boxShadow: (state.isFocused || state.isActive) ? 0 : 0,
      paddingLeft: 0,
      color: 'white',
      fontSize: 15,
      fontWeight: 400,
      paddingTop: 2,
      outline: 'none',
      marginTop: 14,
    }),
    input: (provided) => ({
      ...provided,
      color: '#ffffff',
      fontSize: 15,
      fontWeight: 400,
      // fontFamily: 'Gilroy-Regular'
    }),
    option: (provided, state) => ({
      ...provided,
      color: (state.isSelected) ? 'white' : 'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400,
      backgroundColor: state.isSelected ? "#46C695" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
      fontSize: 15,
      fontWeight: 400,
    }),
  }
