import { useState, useEffect } from 'react';
import { getProducts, editProduct } from 'api/products';
import { BsX } from 'react-icons/bs';
import { useApp } from 'context';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState({});

  const handleProducts = async () => {
    try {
      let { data } = await getProducts();
      setProducts(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleProducts()
  }, [])

  return(
    <div>
      {products?.map((p, key) => (
        <div className='js-ac' key={key} style={{ height: 120, cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)' }} onClick={() => setModal(p)}>
          <img alt='' src={p?.imageUrl} style={{ width: 90, height: 90, objectFit: 'cover', flexShrink: 0 }} />
          <div style={{ marginLeft: 12, lineHeight: 1, height: 90 }}>
            <div>{p?.title}</div>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,.35)', marginTop: 5 }}>{p?.price}</div>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,.35)' }}>{p?.canonicalUrl}</div>
            <div style={{ fontSize: 14, color: 'rgba(255,255,255,.35)' }}>{p?.merchantName}</div>
          </div>
        </div>
      ))}
      <EditModal modal={modal} setModal={setModal} handleProducts={handleProducts} />
    </div>
  )
}

export default Products;

const EditModal = ({ modal, setModal, handleProducts }) => {
  const { setLoading, addToast } = useApp();

  const handleProdUpdate = (type, value) => {
    setModal({...modal, [type]: value })
  }

  const handleEdit = async () => {
    try {
      setLoading(true);
      let changes = {
        price: modal?.price,
        title: modal?.title,
        imageUrl: modal?.imageUrl
      }
      await editProduct(modal?.id, changes);
      await handleProducts();
      setModal({});
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error editing product', type: "ERROR" });
    }
  }

  if (modal?.id) {
    return(
      <>
        <div style={{ width: '100vw', height: '100vh', left: 0, top: 0, position: 'fixed', background: 'rgba(0,0,0,.25)', zIndex: 99 }} onClick={() => setModal({})} />
        <div className='d-flex align-items-center' style={{ position: 'fixed', width: 'calc(100% - 340px)', left: 300, top: '50%', transform: 'translate(0%, -50%)', background: 'black', overflow: 'auto', zIndex: 100, padding: 20, flexDirection: 'column' }}>
          <div style={{ position: 'absolute', right: 8, top: 8, color: 'white', cursor: 'pointer' }} onClick={() => setModal({})}>
            <BsX style={{ fontSize: 26 }} />
          </div>
          <input style={inputStyles} placeholder='TITLE' value={modal?.title || ''} onChange={(e) => handleProdUpdate('title', e.target.value)} />
          <input style={inputStyles} placeholder='PRICE' value={modal?.price || ''} onChange={(e) => handleProdUpdate('price', e.target.value)} />
          <input style={inputStyles} placeholder='IMAGE URL' value={modal?.imageUrl || ''} onChange={(e) => handleProdUpdate('imageUrl', e.target.value)} />
          <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d' }} onClick={handleEdit}>EDIT</div>
          <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d', marginBottom: 10 }} onClick={() => setModal({})}>CANCEL</div>
        </div>
      </>
    )
  } else {
    return null;
  }
}

const inputStyles = { width: 360, background: 'transparent', border: '2px solid #5c7d6d', height: 46, paddingLeft: 10, borderRadius: 5, outline: 'none', color: 'white', marginTop: 14 }
