import { client } from './client';
import { addUrlQuery } from './utils';

export const getLocations = async (query) => {
  const url = addUrlQuery(`locations`, query);
  const { data } = await client.get(url);
  return data;
}

export const addLocation = async (body) => {
  let response = await client.post("locations", body);
  return response;
}

export const editLocation = async (id, body) => {
  let response = await client.put(`locations/${id}`, body);
  return response;
}

export const deleteLocation = async (id) => {
  let response = await client.delete(`locations/${id}`);
  return response;
}

export const uploadLocationFiles = async (body) => {
  let response = await client.post(`upload-location-photos`, body);
  return response;
}

export const deleteLocationPhoto = async (body) => {
  let response = await client.post(`delete-location-photo`, body);
  return response;
}
