//External Imports
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

//Internal Imports
import { getType } from 'api/users';
import { getCurrent } from 'api/auth';
import AppContextWrapper from 'context';
// import LocationTypesScreen from './DashScreens/LocationTypes';
import Applications from './DashScreens/Applications';
import PostErrors from './DashScreens/PostErrors';
import Locations from './DashScreens/Locations';
import Blogs from './DashScreens/Blogs';
import Docs from './DashScreens/Docs';
// import LocationSearches from './DashScreens/LocationSearches';
import LoadingSpinner from 'components/LoadingSpinner';
import Users from './DashScreens/Users';
import Reports from './DashScreens/Reports';
import Activities from './DashScreens/Activities';
import Stores from './DashScreens/Stores';
import Menu from '../components/Menu';
// import FourSquare from './DashScreens/4Square';
import Products from './DashScreens/Products';
import LocationTypes from './DashScreens/LocationTypes';
import AdminToasts from 'components/Toast';
import Outreach from './DashScreens/Outreach';
import ContactFormRes from './DashScreens/ContactFormRes';

const Dash = () => {
  const [dashPage, setDashPage] = useState('applications');
  document.body.style.overflow = 'hidden';

  // useEffect(() => {
  //   const handleStatus = async () => {
  //     await getType();
  //   }
  //   handleStatus();
  // //eslint-disable-next-line
  // }, [])

  const getDashPage = () => {
    if (dashPage === 'applications') {
      return <Applications />
    } else if (dashPage === 'users') {
      return <Users />
    } else if (dashPage === 'locations') {
      return <Locations />
    } else if (dashPage === 'reports') {
      return <Reports />
    } else if (dashPage === 'activities') {
      return <Activities />
    } else if (dashPage === 'stores') {
      return <Stores />
    } else if (dashPage === 'locationTypes') {
      return <LocationTypes />
    } else if (dashPage === 'products') {
      return <Products />
    } else if (dashPage === 'blogs') {
      return <Blogs />
    } else if (dashPage === 'docs') {
      return <Docs />
    } else if (dashPage === 'outreach') {
      return <Outreach />
    } else if (dashPage === 'contactFrom') {
      return <ContactFormRes />
    } else {
      return null;
    }
  }

  const of = dashPage === 'users' ? 'hidden' : 'auto'

  return(
    <div style={{ width: '100%', height: '100vh', overflowY: 'hidden', background: '#19181a', position: 'relative' }} className='js-ac como-bold'>
      <Menu dashPage={dashPage} setDashPage={setDashPage} />
      <div style={{ width: 'calc(100vw - 260px)', height: '100vh', overflowY: of, position: 'relative', color: 'white' }}>
        {getDashPage()}
      </div>
      <AdminToasts />
    </div>
  )
}

const WrappedDash = () => {
  return (
    <AppContextWrapper>
      <Dash />
      <LoadingSpinner />
    </AppContextWrapper>
  )
}

export default WrappedDash;
