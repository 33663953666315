import { client } from './client';

export const getFile = async (id) => {
  let { data } = await client.get(`files/${id}`);
  return data;
}

export const deleteFile = async (id) => {
  let { data } = await client.delete(`files/${id}`);
  return data;
}
