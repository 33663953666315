import { client } from './client';

export const getStores = async () => {
  let response = await client.get(`merchants`);

  if (response?.status === 200) {
    return response;
  } else {
    console.error('Get users error')
  }
}

export const editStore = async (id, changes) => {
  let response = await client.put(`merchants/${id}`, changes);

  if (response?.status === 200) {
    return response;
  } else {
    console.error('Get users error')
  }
}

export const addStore = async (newStore) => {
  let response = await client.post(`merchants`, newStore);

  if (response?.status === 200) {
    return response;
  } else {
    console.error('Create store error')
  }
}
