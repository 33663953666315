import { client } from './client';
import { addUrlQuery } from './utils';

export const getBlogs = async () => {
  const url = addUrlQuery(`posts`, { filter: { slug: { _not: null } } });
  let {data} = await client.get(url);
  return data;
}

export const getBlog = async (id) => {
  const url = addUrlQuery(`posts/${id}`, { filter: { slug: { _not: null } } });
  let {data} = await client.get(url);
  return data;
}

export const addBlog = async (body) => {
  let {data} = await client.post(`posts`, body);
  return data;
}

export const updateBlog = async (id, body) => {
  let {data} = await client.put(`posts/${id}`, body);
  return data;
}

export const deleteBlog = async (id) => {
  let {data} = await client.delete(`posts/${id}`);
  return data;
}

export const addSection = async (body) => {
  let {data} = await client.post(`sections`, body);
  return data;
}

export const removeSection = async (id) => {
  let {data} = await client.delete(`sections/${id}`);
  return data;
}

export const updateSection = async (id, body) => {
  let {data} = await client.put(`sections/${id}`, body);
  return data;
}

// export const setSectionProduct = async (id, productIds) => {
//   let {data} = await client.post(`sections/${id}/products/set`, { ids: productIds });
//   return data;
// }

export const getSectionProducts = async (id) => {
  let {data} = await client.get(`sections/${id}/products`);
  return data;
}

export const addSectionProduct = async (id, productIds) => {
  let {data} = await client.post(`sections/${id}/products/add`, { ids: productIds });
  return data;
}

export const removeSectionProduct = async (id, productIds) => {
  let {data} = await client.post(`sections/${id}/products/remove`, { ids: productIds });
  return data;
}

export const uploadBlogImage = async (body) => {
  let {data} = await client.post(`docs/upload-image`, body);
  return data;
}

export const updateBlogCoverPhoto = async (id, fileIds) => {
  const { data } = await client.post(`posts/${id}/files/set`, { ids: fileIds });
  return data;
}
