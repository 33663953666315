import { client } from './client';
import { addUrlQuery } from './utils';

export const getProducts = async (query) => {
  const url = addUrlQuery(`products`, query);
  let {data} = await client.get(url);
  return data;
}

export const editProduct = async (id, changes) => {
  let {data} = await client.put(`product/${id}`, changes);
  return data;
}
