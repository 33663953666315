import "react-toggle/style.css"
import { useState, useEffect } from 'react';
import { useApp } from 'context';
import Papa from "papaparse";
// @ts-ignore
// import Toggle from 'react-toggle';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { IoTrashOutline } from "react-icons/io5";
import GeoSearch from 'components/GeoSearch';
import { getLocations, addLocation, editLocation, deleteLocation, uploadLocationFiles } from 'api/locations';

const Locations = () => {
  const { setLoading, locationTypes } = useApp();
  const [searchVal, setSearchVal] = useState('');
  const [selectedLoc, setSelectedLoc] = useState({});
  const [addLoc, setAddLoc] = useState({});
  const [initial, setInitial] = useState([]);

  const [veiwCsv, setVeiwCsv] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const getInitial = async () => {
    try {
      setLoading(true);
      let data = await getLocations();
      setInitial(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInitial()
  }, [])

  const handleSelect = (l) => {
    setSearchVal('');
    setSelectedLoc(l)
  }

  const handleAdd = () => {
    setAddLoc({ open: true, verified: true })
  }

  const handleCsv = (e) => {
    let files = e.target.files;
    if (files?.length > 0) {
      setLoading(true);
      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          let formatted = results.data?.map((l) => ({
            name: l?.name,
            lat: l?.lat,
            lng: l?.lng,
            description: l?.description,
            address: l?.address,
            type: locationTypes?.find((lt) => lt?.id === l?.type),
            id: uuidv4()
          }))
          setCsvData(formatted);
          setLoading(false);
          setVeiwCsv(true);
        },
      });
    }
  }

  return(
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', padding: 20 }}>
        <GeoSearch value={searchVal} onChange={handleSelect} style={{ marginTop: 0 }} />
        <div className='js-ac' style={{ width: 400 }}>
          <div className='btn1 me-3' style={{ position: 'relative' }}>
            UPLOAD CSV
            {!veiwCsv && <input onChange={handleCsv} type='file' accept=".csv" style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', opacity: 0 }} />}
          </div>
          <div className='btn1' onClick={handleAdd}>ADD LOCATION</div>
        </div>
        <ViewCsv csvData={csvData} setCsvData={setCsvData} open={veiwCsv} setOpen={setVeiwCsv} getInitial={getInitial} />
        <AddLocationModal addLoc={addLoc} setAddLoc={setAddLoc} />
        <EditLocationModal selectedLoc={selectedLoc} setSelectedLoc={setSelectedLoc} getInitial={getInitial} />
      </div>
      <div style={{ padding: 20 }}>
        {initial?.map((loc) => (
          <div onClick={() => setSelectedLoc(loc)} key={loc?.id} style={{ width: '100%', padding: '20px 0px', color: 'white', borderBottom: '1px solid rgba(255,255,255,.25)' }} className='d-flex justify-content-between align-items-center'>
            <div>
              {loc?.name}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Locations;

const isOdd = (num) => {
  return num % 2
}

const getError = (str, noRed) => {
  if (!noRed) {
    if (str?.length === 0 || !str) {
      return true
    }  
  }
}

const ViewCsv = ({ csvData = [], setCsvData = () => {}, open, setOpen, getInitial }) => {
  const { setLoading, addToast } = useApp();

  const handleClose = () => {
    setOpen(false);
    setCsvData([]);
  }


  if (open) {
    const checkForErrors = () => {
      let hasErrors = csvData?.filter((l) => !l?.name || !l?.lat || !l?.lng || !l?.type?.name);
      if (hasErrors?.length > 0) {
        return true;
      }
    }

    const handleDelete = (loc) => {
      let remaining = csvData?.filter((l) => l?.id !== loc?.id);
      setCsvData(remaining);
      if (remaining?.length === 0) {
        setOpen(false);
      }
    }

    const handleAdd = async () => {
      if (checkForErrors()) {
        addToast({ type: "ERROR", title: "Missing data on one or more locations", body: "Correct CSV and re-upload or delete faulty location" })
      } else if (csvData?.length > 0) {
        let formatted = csvData?.map((l) => ({
          name: l?.name,
          description: l?.description,
          fullAddress: l?.address,
          locationTypeId: l?.type?.id,
          coords: {
            type: 'Point',
            coordinates: [l?.lng, l?.lat]
          }
        }));
        try {
          setLoading(true);
          await addLocation(formatted);
          await getInitial()
          setLoading(false);
          handleClose();
        } catch(err) {
          setLoading(false);
          addToast({ type: "ERROR", title: "There was an issue adding these locations", body: err?.message })
        }
      } else {
        handleClose();
      }
    }

    return(
      <div style={{ position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer', padding: '60px 20px' }}>
        <div style={{ position: 'absolute', right: 20, top: 20, color: 'white' }} onClick={handleClose}>X</div>
        <div className='jb-ac pt-1 pb-1' style={{ borderTop: '1px solid rgba(255,255,255,.2)', fontWeight: 800, fontSize: 12, color: "#5c7d6d" }}>
          <div style={{ width: "3%" }} className="js-ac" />
          <div style={{ width: "25%", }}>{"NAME"}</div>
          <div style={{ width: "30%", }}>{"DESCRIPTION"}</div>
          <div style={{ width: "22%", }}>{"ADDRESS"}</div>
          <div style={{ width: "10%", }}>{"LAT"}</div>
          <div style={{ width: "10%", }}>{"LNG"}</div>
          <div style={{ width: "20%", }}>{"TYPE"}</div>
        </div>
        {csvData?.map((l, idx) => (
          <div className='jb-ac pt-1 pb-1' key={idx} style={{ borderTop: '1px solid rgba(255,255,255,.2)', background: isOdd(idx) ? "" : "rgba(255,255,255,.1)", fontSize: 12 }}>
            <div style={{ width: "3%" }} onClick={() => handleDelete(l)} className="js-ac">
              <IoTrashOutline />
            </div>
            <CsvColumn value={l?.name} width="25%" />
            <CsvColumn value={l?.description} width="30%" noRed={true} />
            <CsvColumn value={l?.address} width="22%" noRed={true} />
            <CsvColumn value={l?.lat} width="10%" />
            <CsvColumn value={l?.lng} width="10%" />
            <CsvColumn value={l?.type?.name} width="20%" />
          </div>
        ))}
        <div style={{ width: '100%', height: 1, background: "rgba(255,255,255,.3)" }} />
        <div className='jc-ac' style={{ position: 'absolute', left: 0, bottom: 0, color: 'white', background: 'black', padding: "10px 20px", width: '100%' }}>
          <div className="btn1 me-3" style={{ width: 240, maxWdith: '49%' }} onClick={handleClose}>Cancel</div>
          <div className="btn1" style={{ width: 240, maxWdith: '49%' }} onClick={handleAdd}>Add Locations</div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

const CsvColumn = ({ value, width, noRed }) => {
  return(
    <div style={{ width: width, color: getError(value, noRed) ? "red" : "", overflow: 'hidden' }}>{value || "MISSING"}</div>
  )
}

const AddLocationModal = ({ addLoc, setAddLoc }) => {
  const { setLoading, addToast, locationTypes } = useApp();
  // const [files, setFiles] = useState([]);

  const handleLocUpdate = (changes = {}) => {
    setAddLoc({...addLoc, ...changes })
  }

  const handleAdd = async () => {
    try {
      if (addLoc?.locationTypeId) {
        setLoading(true);
        let newLoc = {
          ...addLoc,
          coords: {
            type: 'Point',
            coordinates: [addLoc?.lng, addLoc?.lat]
          }
        }
        await addLocation(newLoc);
        setAddLoc({})
        setLoading(false);
      } else {
        addToast({ title: "Error", body: 'Please select a type', type: "ERROR" });
      }
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error adding location', type: "ERROR" });
    }
  }

  const handleClose = () => {
    setAddLoc({})
  }

  const options = locationTypes?.map((act) => ({ ...act, value: act.id, label: act.name }));

  if (addLoc?.open) {
    return(
      <div style={{ position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer' }}>
        <div style={{ position: 'absolute', right: 20, top: 20, color: 'white' }} onClick={handleClose}>X</div>
        <div className='d-flex align-items-center' style={{ flexDirection: 'column', marginTop: 60, paddingBottom: 100 }}>
          <input style={inputStyles} placeholder='NAME' value={addLoc?.name || ''} onChange={(e) => handleLocUpdate({ name: e.target.value })} />
          <Select
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isSearchable={true}
            name="locationType"
            options={options}
            styles={selectStyles}
            placeholder='TYPE'
            onChange={(newValue) => handleLocUpdate({ locationTypeId: newValue?.id })}
          />
          <input style={inputStyles} placeholder='DESCRIPTION' value={addLoc?.description || ''} onChange={(e) => handleLocUpdate({ description: e.target.value })} />
          <input style={inputStyles} placeholder='LAT' value={addLoc?.lat || ''} onChange={(e) => handleLocUpdate({ lat: e.target.value })} />
          <input style={inputStyles} placeholder='LONG' value={addLoc?.lng || ''} onChange={(e) => handleLocUpdate({ lng: e.target.value })} />
          <input style={inputStyles} placeholder='ADDRESS' value={addLoc?.fullAddress || ''} onChange={(e) => handleLocUpdate({ fullAddress: e.target.value })} />
          <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d' }} onClick={handleAdd}>ADD</div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

const EditLocationModal = ({ selectedLoc, setSelectedLoc, getInitial }) => {
  const { setLoading, addToast, locationTypes } = useApp();
  const [hasChanges, setHasChanges] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [files, setFiles] = useState([]);
  const dataUpdates = Object.keys(hasChanges)?.length > 0;
  const enable = dataUpdates || files?.length > 0;
  const geo = selectedLoc?.coords?.coordinates || [];

  const handleLocUpdate = (type, value) => {
    setHasChanges({ ...hasChanges, type })
    setSelectedLoc({...selectedLoc, [type]: value })
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      setDeleteModal(false)
      await deleteLocation(selectedLoc?.id);
      await getInitial();
      setSelectedLoc({});
      setHasChanges({});
      setFiles([])
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setDeleteModal(false)
      addToast({ title: "Error", body: 'Error deleting location', type: "ERROR" });
    }
  }

  const handleEdit = async () => {
    if (enable) {
      try {
        setLoading(true);
        let changes = {
          name: selectedLoc?.name,
          coords: {
            ...selectedLoc?.coords,
            coordinates: [
              selectedLoc?.coords?.coordinates[0],
              selectedLoc?.coords?.coordinates[1]
            ]
          },
          fullAddress: selectedLoc?.fullAddress,
          description: selectedLoc?.description,
          locationTypeId: selectedLoc?.locationTypeId,
        }
        await editLocation(selectedLoc?.id, changes);
        if (files?.length > 0) {
          let formData = new FormData();
          files.map((f) => formData.append("files", f.file, f?.file?.type));
          formData.append("locationId", selectedLoc?.id);
          await uploadLocationFiles(formData)
        }
        await getInitial();
        setSelectedLoc({});
        setHasChanges({});
        setFiles([])
        setLoading(false);
      } catch(err) {
        setLoading(false);
        addToast({ title: "Error", body: 'Error editing location', type: "ERROR" });
      }
    }
  }

  const options = locationTypes?.map((act) => ({ ...act, value: act.id, label: act.name }));
  const existingType = options?.find((act) => act?.id === selectedLoc?.locationTypeId) || {};

  if (selectedLoc?.id) {
    return(
      <div style={{ position: 'fixed', width: '100%', height: '100vh', left: 0, top: 0, background: 'black', overflow: 'auto', cursor: 'pointer' }}>
        <div style={{ position: 'fixed', right: 20, top: 20, color: 'white' }} onClick={() => setSelectedLoc({})}>X</div>
        <div className='d-flex align-items-center' style={{ flexDirection: 'column', marginTop: 60, paddingBottom: 100 }}>
          <input style={inputStyles} placeholder='NAME' value={selectedLoc?.name || ''} onChange={(e) => handleLocUpdate('name', e.target.value)} />
          <Select
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isSearchable={true}
            name="locationType"
            options={options}
            styles={selectStyles}
            placeholder='TYPE'
            value={existingType}
            onChange={(newValue) => handleLocUpdate('locationTypeId', newValue?.id)}
          />
          <input style={inputStyles} placeholder='DESCRIPTION' value={selectedLoc?.description || ''} onChange={(e) => handleLocUpdate('description', e.target.value)} />
          <input style={inputStyles} placeholder='LAT' value={geo[1] || ''} onChange={(e) => handleLocUpdate('lat', e.target.value)} />
          <input style={inputStyles} placeholder='LONG' value={geo[0] || ''} onChange={(e) => handleLocUpdate('lng', e.target.value)} />
          <input style={inputStyles} placeholder='ADDRESS' value={selectedLoc?.fullAddress || ''} onChange={(e) => handleLocUpdate('fullAddress', e.target.value)} />
          <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d', opacity: enable ? 1 : .5 }} onClick={handleEdit}>EDIT</div>
          <div className='btn1' style={{ maxWidth: 360, marginTop: 14, border: '2px solid #5c7d6d' }} onClick={() => setSelectedLoc({})}>CANCEL</div>
          <div className='btn-danger' style={{ maxWidth: 360, marginTop: 14 }} onClick={() => setDeleteModal(true)}>DELETE</div>
        </div>

        {deleteModal && (
          <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', padding: '40px 60px', background: 'white', borderRadius: 5, color: 'black' }} className='shadow'>
            <div style={{ textAlign: 'center', marginBottom: 30 }}>ARE YOU SURE?</div>
            <div className='d-flex justify-content-center' style={{ cursor: 'pointer' }}>
              <div style={{ marginRight: 40 }} onClick={() => setDeleteModal(false)}>Cancel</div>
              <div style={{ marginLEft: 40 }} onClick={handleDelete}>Delete</div>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return null;
  }
}

const inputStyles = { width: 360, background: 'transparent', border: '2px solid #5c7d6d', height: 42, paddingLeft: 10, borderRadius: 2, outline: 'none', color: 'white', marginTop: 14 }

export const selectStyles = {
    indicatorSeparator: (provided) => ({
      ...provided,
      background: 'rgba(0,0,0,0)',
      color: 'rgba(0,0,0,0)',
      opacity: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color:'rgba(0,0,0,0)',
      opacity: 0
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100000001,
      minHeight: 50,
      // background: 'rgba(0,0,0,0)',
      // width: 'calc(100% + 6px)',
      marginLeft: -3
    }),
    container: (provided, state) => ({
      ...provided,
      width: '360px',
    }),
    control: (provided, state) => ({
      ...provided,
      height: 42,
      flexShrink: 0,
      width: '100%',
      background:'transparent',
      border: (state.isFocused || state.isActive || state.isHovered) ? "2px solid rgb(92, 125, 109)" : "2px solid rgb(92, 125, 109)",
      borderRadius: 2,
      boxShadow: (state.isFocused || state.isActive) ? 0 : 0,
      paddingLeft: 4,
      color: 'white',
      fontSize: 15,
      fontWeight: 400,
      paddingTop: 2,
      outline: 'none',
      marginTop: 14,
    }),
    input: (provided) => ({
      ...provided,
      color: '#ffffff',
      fontSize: 15,
      fontWeight: 400,
      // fontFamily: 'Gilroy-Regular'
    }),
    option: (provided, state) => ({
      ...provided,
      color: (state.isSelected) ? 'white' : 'rgb(17, 24, 39)',
      fontSize: 15,
      fontWeight: 400,
      backgroundColor: state.isSelected ? "#46C695" : "",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
      fontSize: 15,
      fontWeight: 400,
    }),
  }
