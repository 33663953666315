import { IoCheckmarkOutline } from 'react-icons/io5';
import { useState, useEffect, useRef } from 'react';
import { getLocations, addLocation } from 'api/locations'
import { Portal } from 'react-portal';

const GeoSearch = ({ value, onChange, placeholder, style = {} }) => {
  const [searchVal, setSearchVal] = useState(value?.name || "");
  const [options, setOptions] = useState([]);
  const [boundingRect, setBoundingRect] = useState({});
  const [valueVoundingRect, setValueBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length;
  const valueRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option) => {
    const formattedValues = value?.map((v) => v.value);
    return !!formattedValues?.includes(option?.value)
  }

  useEffect(() => {
    if (valueRef?.current) {
      setValueBoundingRect(valueRef.current?.getBoundingClientRect())
    }
  }, [valueLength])

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
  }, [inputRef, focus, valueLength])

  const handleOptionsClose = () => {
    setFocus(false)
  }

  const handleSelect = async (val) => {
    setSearchVal(val?.name)
    setFocus(false)
    onChange(val)
  }

  const handleType = async (e) => {
    let newVal = e.target.value;
    setSearchVal(newVal);
    try {
      let data = await getLocations({ filter: { name: { _iLike: `%25${newVal}%25` } } });
      if (data?.length > 0) {
        setOptions(data);
      } else {
        setOptions([]);
      }
    } catch(err) {
      console.log(err);
    }
  }

  return(
    <div className='' style={{ position: 'relative' }}>
      <input ref={inputRef} value={searchVal} onChange={(e) => handleType(e)} onFocus={() => setFocus(true)} className='input-styles light-grey-bg' placeholder="LOCATION" style={style} />

      {focus && (
        <Portal>
          <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)' }} onClick={handleOptionsClose} />
          <div style={{ position: 'absolute', background: 'rgb(25, 24, 26)', borderRadius: 4, zIndex: 1001, left: boundingRect.x, top: boundingRect.bottom + 6, width: boundingRect.width }} className='shadow'>
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
              {options?.length > 0 && (
                options.map((option, key) => (
                  <div key={key} onClick={() => handleSelect(option)} style={{ width: '100%', height: 32, fontSize: 14, cursor: 'pointer', color: 'white' }} className='mb-2 js-ac ps-2 hover-item'>
                    {option?.name}
                  </div>
                ))
              )}
              {options?.length === 0 && (
                <div style={{ color: 'white' }} className='jc-ac mb-2'>No Options</div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}

export default GeoSearch;

export const CheckBox = ({ title, value, setValue, className = '' }) => {
  return(
    <div onClick={(e) => setValue(!value, e)} className={`js-ac ${className}`} style={{ fontFamily: 'Gilroy', cursor: 'pointer' }}>
      <div style={{ width: 16, height: 16, border: value ? '' : '1px solid #0D72C5', background: value ? '#0D72C5' : '', borderRadius: 4, marginRight: 8, flexShrink: 0 }} className='jc-ac'>
        {value && <IoCheckmarkOutline style={{ color: 'white', fontSize: 16 }} />}
      </div>
      {title}
    </div>
  )
}
