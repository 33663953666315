import { client } from './client';

export const getActivities = async () => {
  try {
    let response = await client.get(`activities`);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
}

export const editActivity = async (id, body) => {
  try {
    let response = await client.put(`activity/${id}`, body);
    return response;
  } catch(err) {
    console.error('API ERROR')
  }
}

export const uploadActivityIcon = async (body) => {
  // try {
  //   let response = await client.post(`admin/upload-activity-icon`, body);
  //   return response;
  // } catch(err) {
  //   console.error('API ERROR')
  // }
}
