import { useState, useEffect } from 'react';
import { getUser, getUsersOverview, getUsers } from 'api/users';
import { deletePost } from 'api/posts';
import { useApp } from 'context';
import { IoRefresh } from 'react-icons/io5';
import { BsX } from 'react-icons/bs';
import UserIcon from 'components/UserIcon';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Users = () => {
  const { setLoading, addToast } = useApp();
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);
  const [recentUsers, setRecentUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [basics, setBasics] = useState({});
  const svl = searchValue?.length;
  const showDropdown = svl > 0;

  const handleBasics = async () => {
    try {
      let [data, usersData] = await Promise.all([
        getUsersOverview(),
        getUsers({ order: "-createdAt", limit: 50 })
      ])
      setBasics(data);
      setRecentUsers(usersData)
    } catch(err) {
      addToast({ title: "Error", body: 'Error getting data', type: "ERROR" });
    }
  }

  useEffect(() => {
    handleBasics()
  //eslint-disable-next-line
  }, [])

  const handleSelect = (user) => {
    setSelectedUser(user);
    setSearchValue('');
    setUsers([])
  }

  const handleSearch = async (e) => {
    try {
      let newVal = e.target.value;
      setSearchValue(newVal);
      if (newVal?.length > 0) {
        let response = await getUsers({ filter: { username: { _iLike: `%25${newVal}%25` } } });
        setUsers(response);
      } else {
        setUsers([]);
      }
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error searching users', type: "ERROR" });
    }
  }

  const handleRefresh = async () => {
    try {
      setLoading(true);
      let data = await getUsersOverview();
      setBasics(data);
      setLoading(false);
    } catch(err) {
      addToast({ title: "Error", body: 'Error getting data', type: "ERROR" });
    }
  }

  return(
    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', padding: 20 }}>

      <div className='jb-ac' style={{ width: '100%', paddingBottom: 20, borderBottom: '1px solid rgba(255,255,255,.12)' }}>
        <div className='js-ac'>
          <div style={{ marginRight: 24 }}>
            <div style={{ fontSize: 12, color: 'rgba(255,255,255,.4)' }}>TOTAL</div>
            <div style={{ lineHeight: 1 }}>{basics?.total || 0}</div>
          </div>
          <div style={{ marginRight: 24 }}>
            <div style={{ fontSize: 12, color: 'rgba(255,255,255,.4)' }}>TODAY</div>
            <div style={{ lineHeight: 1 }}>{basics?.today || 0}</div>
          </div>
          <div style={{ marginRight: 24 }}>
            <div style={{ fontSize: 12, color: 'rgba(255,255,255,.4)' }}>PAST 7</div>
            <div style={{ lineHeight: 1 }}>{basics?.lastSeven || 0}</div>
          </div>
          <div style={{ marginRight: 12 }}>
            <div style={{ fontSize: 12, color: 'rgba(255,255,255,.4)' }}>PAST 30</div>
            <div style={{ lineHeight: 1 }}>{basics?.lastThirty || 0}</div>
          </div>
        </div>
        <div className='js-ac'>
          <Search users={users} searchValue={searchValue} showDropdown={showDropdown} handleSearch={handleSearch} handleSelect={handleSelect} />
          <div style={{ marginLeft: 24, fontSize: 20 }} onClick={handleRefresh}>
            <IoRefresh />
          </div>
        </div>
      </div>

      <div style={{ width: '100%', height: 'calc(100vh - 87px)', overflowY: 'auto' }}>
        {recentUsers?.map((user, index) => (
          <RecentUser user={user} index={index} key={`${index}-${user?.username}`} handleSelect={handleSelect} />
        ))}
      </div>
      <SelectedUserModal selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
    </div>
  )
}

const Search = ({ searchValue, showDropdown, users, handleSearch, handleSelect }) => {
  return(
    <div style={{ position: 'relative' }}>
      <input style={{ width: 360, background: 'transparent', border: '2px solid #5c7d6d', height: 46, paddingLeft: 10, borderRadius: 5, outline: 'none', color: 'white' }} placeholder='Search Users' value={searchValue} onChange={(e) => handleSearch(e)} />
      {showDropdown && (
        <div style={{ position: 'absolute', left: 0, top: 54, width: '100%', background: 'black', border: '1px solid rgba(255,255,255,.12)', borderRadius: 5, maxHeight: 260, overflowY: 'auto', boxShadow: '2px 2px 6px rgba(0,0,0,.2)' }}>
          {users?.map((user, key) => (
            <div key={key} style={{ width: '100%', height: 46, paddingLeft: 10, color: 'white', cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)' }} className='js-ac' onClick={() => handleSelect(user)}>
              <UserIcon user={user} className='me-2' style={{ marginRight: 8 }} />
              {user?.username}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const RecentUser = ({ user, index, handleSelect }) => {
  const border = index === 29 ? '' : '1px solid rgba(255,255,255,.12)';

  return(
    <div style={{ width: '100%', height: 60, color: 'white', borderBottom: border, cursor: 'pointer' }} className='js-ac' onClick={() => handleSelect(user)}>
      <UserIcon user={user} className='me-2' style={{ marginRight: 8 }} />
      <div>{user?.username}</div>
    </div>
  )
}

export default Users;

const SelectedUserModal = ({ selectedUser, setSelectedUser }) => {
  const { setLoading, addToast } = useApp();
  const [fullUser, setFullUser] = useState({});
  const [selectedPost, setSelectedPost] = useState({});

  const handleUser = async () => {
    try {
      setLoading(true);
      let {data} = await getUser(selectedUser?.id);
      setFullUser(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error getting user', type: "ERROR" });
    }
  }

  useEffect(() => {
    if (!selectedUser?.id) {
      setFullUser({});
      setSelectedPost({})
    } else {
      handleUser();
    }
  //eslint-disable-next-line
  }, [selectedUser])

  if (selectedUser?.id) {
    const width = window.innerWidth;
    const modalWidth = width - 340;
    const postSize = modalWidth / 6;
    const sortedPosts = fullUser?.posts?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const handleDelete = async () => {
      try {
        setLoading(true);
        await deletePost({ postId: selectedPost?.id });
        setSelectedPost({})
        let response = await getUser(selectedUser?.id);
        setFullUser(response?.data);
        setLoading(false);
      } catch(err) {
        setLoading(false);
        addToast({ title: "Error", body: 'Error removing user', type: "ERROR" });
      }
    }

    return(
      <>
        <div style={{ width: '100vw', height: '100vh', left: 0, top: 0, position: 'fixed', background: 'rgba(0,0,0,.25)', zIndex: 99 }} onClick={() => setSelectedUser({})} />
        <div style={{ position: 'fixed', width: 'calc(100% - 340px)', height: 'calc(100vh - 80px)', left: 300, top: 40, background: 'black', overflow: 'auto' , zIndex: 100 }}>
          <div style={{ position: 'absolute', right: 8, top: 8, color: 'white', cursor: 'pointer' }} onClick={() => setSelectedUser({})}>
            <BsX style={{ fontSize: 26 }} />
          </div>
          <div className='container' style={{ padding: 20, overflowX: 'hidden' }}>
            <div className='d-flex align-items-center'>
              <img alt='' src={`${CDN_ROUTE}${fullUser?.profilePictureURL}`} style={{ width: 40, height: 40, borderRadius: '50%', border: '1.5px solid white' }} />
              <div style={{ color: 'white', marginLeft: 14, fontSize: 18, lineHeight: 1 }}>
                {fullUser?.username}
                <div style={{ fontSize: 14, color: '', lineHeight: 1 }}>{fullUser?.type}</div>
              </div>
            </div>
            <div style={{ color: 'white', marginTop: 12, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>BIO:</div>
              {fullUser?.bio || "NONE"}
            </div>

            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>BLOG LINK:</div>
              {fullUser?.blogLink || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>BOOKING LINK:</div>
              {fullUser?.bookingLink || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>EMAIL:</div>
              {fullUser?.email || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>INSTAGRAM:</div>
              {fullUser?.instagram || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>LOCATION:</div>
              {fullUser?.location?.label || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>PODCAST LINK:</div>
              {fullUser?.podcastLink || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>STORE LINK:</div>
              {fullUser?.storeLink || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>WEBSITE:</div>
              {fullUser?.website || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>TIKTOK:</div>
              {fullUser?.tiktok || "NONE"}
            </div>
            <div style={{ color: 'white', marginTop: 4, fontSize: 14 }}>
              <div style={{ fontSize: 10, color: 'rgba(255,255,255,.5)'}}>ACTIVITIES:</div>
              <div className='js-ac' style={{ flexWrap: 'wrap' }}>
                {fullUser?.activities?.map((act, idx) => (
                  <ActItem key={idx} idx={idx} act={act} user={fullUser} />
                ))}
              </div>
            </div>
          </div>
          <div className='d-flex' style={{ flexWrap: 'wrap'  }}>
            {sortedPosts?.map((p, key) => <img alt='' src={`${CDN_ROUTE}${p?.filePath}/small.jpeg`} onClick={() => setSelectedPost(p)} key={key} style={{ width: postSize, height: postSize, objectFit: 'cover' }} />)}
          </div>
          {selectedPost?.id && (
            <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', padding: '40px 60px', background: 'white', borderRadius: 5, color: 'black', zIndex: 101 }} className='shadow'>
              <div style={{ textAlign: 'center', marginBottom: 30 }}>DELETE POST</div>
              <div className='d-flex justify-content-center' style={{ cursor: 'pointer' }}>
                <div style={{ marginRight: 40 }} onClick={() => setSelectedPost({})}>Cancel</div>
                <div style={{ marginLeft: 40, color: 'red' }} onClick={handleDelete}>Delete</div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  } else {
    return null;
  }
}

const ActItem = ({ act, idx, user }) => {
  const actName = act?.name;
  const actsCount = user?.activities?.length;
  const isLast = idx === actsCount - 1;
  const label = isLast ? actName : `${actName}, `;

  return(
    <div style={{ marginRight: 4 }}>{label}</div>
  )
}
