//External Imports
import { useApp } from 'context';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

//Internal Imports
import { getContactFormSubmissions, dismissReport } from 'api/reports';

const ContactFormRes = () => {
  const { setLoading, addToast } = useApp();
  const [submissions, setSubmissions] = useState([]);

  const handleSubmissions = async () => {
    try {
      setLoading(true);
      let {data} = await getContactFormSubmissions();
      setSubmissions(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error getting submissions', type: "ERROR" });
    }
  }

  useEffect(() => {
    handleSubmissions();
  //eslint-disable-next-line
  }, []);

  const handleDismiss = async (id) => {
    try {
      setLoading(true);
      await dismissReport(id);
      handleSubmissions()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error marking replied', type: "ERROR" });
    }
  }

  return(
    <div style={{ width: '100%', height: '100%', fontSize: 14, padding: 20 }}>
      {submissions?.map((s) => <SubmissionItem key={s?.id} s={s} handleDismiss={handleDismiss} />)}
    </div>
  )
}

export default ContactFormRes;

const SubmissionItem = ({ s, handleDismiss }) => {
  return(
    <div className='pb-3' style={{ borderBottom: '1px solid rgba(255,255,255,.2)' }}>
      <div className='jb-ac'>
        <div className='js-ac'>
          <div>
            <div style={{ fontSize: 12, color: "#5c7d6d" }}>Username</div>
            <div>{s?.user?.username || "UNKNOWN"}</div>
          </div>
          <div className="ms-3">
            <div style={{ fontSize: 12, color: "#5c7d6d" }}>Email</div>
            <div>{s?.user?.email || "UNKNOWN"}</div>
          </div>
          <div className="ms-3">
            <div style={{ fontSize: 12, color: "#5c7d6d" }}>Created</div>
            <div>{safeDate(s)}</div>
          </div>
        </div>
        <div onClick={() => handleDismiss(s?.id)} className='btn1' style={{ paddingLeft: 10, paddingRight: 10, fontSize: 14, height: 40, marginRight: 14, width: 160 }}>MARK REPLIED</div>
      </div>
      <div className="mt-3" style={{ fontSize: 12, color: "#5c7d6d" }}>Message</div>
      <div>{s?.body}</div>
    </div>
  )
}

const safeDate = (s) => {
  if (s?.createdAt) {
    return format(new Date(s?.createdAt), "MMM dd, yyyy hh:mm")
  } else {
    return "UNKNOWN"
  }

}
