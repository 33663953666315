import { Title, SubTitle, SubSubTitle, Paragraph, Spacer, Image, Link, Product, ImageTitleText, TitleTextImage, ImageText, TextImage, Video, Embed, Bullet } from './sections';

const GetSection = ({ blog, section, setSelectedBlog }) => {
  const isSpacer = (section) => {
    let sTypes = ['Spacer', 'Spacer LG', 'Spacer MD', 'Spacer SM'];
    if (sTypes?.includes(section?.sectionType)) {
      return true
    }
  }

  if (section?.sectionType === 'Title') {
    return <Title blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Subtitle') {
    return <SubTitle blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'SubSubtitle') {
    return <SubSubTitle blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Paragraph') {
    return <Paragraph blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Image') {
    return <Image blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Link') {
    return <Link blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Product') {
    return <Product blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'ImageTitleText') {
    return <ImageTitleText blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'TitleTextImage') {
    return <TitleTextImage blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'ImageText') {
    return <ImageText blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'TextImage') {
    return <TextImage blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Video') {
    return <Video blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Embed') {
    return <Embed blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (section?.sectionType === 'Bullet') {
    return <Bullet blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else if (isSpacer(section)) {
    return <Spacer blog={blog} section={section} setSelectedBlog={setSelectedBlog} />
  } else {
    return null;
  }
}

export default GetSection;

export { BlogCard } from './BlogCard';
export { UserSearch } from './components';
export { BlogSectionsModal } from './SectionsModal';
export { BlogSettingsModal, selectStyles } from './SettingsModal';
export { ContentModal, EmbedModal, LinkModal, VideoModal, ImageModal, ProductModal } from './EditModal'
