// External Imports
import Spinner from 'react-bootstrap/Spinner';

// Internal Imports
import { useApp } from "context";

const LoadingSpinner = () => {
  const { loading } = useApp();

  if (loading) {
    return(
      <div style={{ position: 'absolute', left: '50%', top: '50%', width: 60, height: 60, background: 'black', borderRadius: '50%', boxShadow: '4px 4px 10px rgba(0,0,0,.3)', zIndex: 100000000 }} className='jc-ac'>
        <Spinner animation="border" role="status" variant="light">
        </Spinner>
      </div>
    )
  } else {
    return null;
  }

};

export default LoadingSpinner;
