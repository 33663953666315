// External Imports
import axios from "axios";
import { Amplify, Auth } from "aws-amplify";

const unauthenticatedRoutes = ["/login"];

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    signUpVerificationMethod: "code",
    authenticationFlowType: "USER_SRP_AUTH",
  },
});

export const client = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_API_ROUTE,
});

client.interceptors.request.use(
  (config) =>
    Auth.currentSession()
      .then((session) => {
        config.headers["Authorization"] = `Bearer ${session.accessToken.jwtToken}`;
        return config;
      })
      .catch((error) => {
        return config;
      }),
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;
    const message = error.response?.data?.message;

    if (status === 401) {
      window.location.href = "/login";
      // throw new UnauthorizedError(status, message || "Please login in");
    }
    // else if (status === 403) {
    //   throw new ForbiddenError(status, message || "You do not have permission to do this");
    // } else if (status === 404) {
    //   throw new NotFoundError(status, message || "Oh oh.. We can't find what you are looking for");
    // } else if (status < 500) {
    //   throw new UserError(status, message || "Something doesn't look right.. Please try again");
    // } else {
    //   throw new ServerError(status, message || "Oh oh.. Something went wrong");
    // }
  }
);
