import React, { useState, useContext, createContext, useEffect } from "react";
import { getLocationTypes } from 'api/locationTypes';
import { getActivities } from 'api/activities';
import { isBefore, subDays } from 'date-fns';
import { getPostsTypes } from 'api/general';
import { getCurrent } from 'api/auth';
import { v4 as uuidv4 } from "uuid";

const AppContext = createContext();

const chicagoLat = 40.7128;
const chicagoLng = -74.0060;

export default function AppContextWrapper({ children }) {
  const [cuurentAuthUser, setCurrentAuthUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState();
  const [center, setCenter] = useState({ lat: chicagoLat, lng: chicagoLng });
  const [zoom, setZoom] = useState(6);
  const [bounds, setBounds] = useState()
  const [searchLat, setSearchLat] = useState('');
  const [searchLng, setSearchLng] = useState('');
  const [toasts, setToasts] = useState([]);
  const [activities, setActivities] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);

  const savedPTs = localStorage.getItem("outsyde_post_types");
  const parsedPTsObj = JSON.parse(savedPTs) || {};
  const parsedPTs = parsedPTsObj?.types || [];
  const [postTypes, setPostTypes] = useState(parsedPTs);

  const handlePostTypes = async () => {
    if (!parsedPTsObj?.date || isBefore(new Date(parsedPTsObj?.date), subDays(new Date(), 7))) {
      try {
        let data = await getPostsTypes();
        let sorted = data?.sort((a, b) => a.name.localeCompare(b.name));
        localStorage.setItem("outsyde_post_types", JSON.stringify({ date: new Date(), types: sorted }))
        setPostTypes(sorted)
      } catch(err) {
        console.log(err);
      }
    }
  }
  // 
  // const handleUser = async () => {
  //   let ddd = await getCurrent();
  //   console.log(ddd);
  //   // setCurrentAuthUser(ddd)
  // }
  //
  // useEffect(() => {
  //   handleUser()
  // }, [])

  const handleLocationTypes = async () => {
    try {
      setLoading(true);
      let { data } = await getLocationTypes();
      setLocationTypes(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error getting location types', type: "ERROR" });
    }
  }

  useEffect(() => {
    handlePostTypes();
    handleLocationTypes();
  }, [])

  const handleActivities = async () => {
    let response = await getActivities();
    setActivities(response?.data)
  }

  useEffect(() => {
    handleActivities();
  }, []);

  const addToast = (toast = {}) => {
    if (toast?.body) {
      let id = uuidv4();
      setToasts([...toasts, { ...toast, id: id }]);
    }
  };

  const appLocalState = {
    map,
    zoom,
    toasts,
    setMap,
    center,
    bounds,
    loading,
    setZoom,
    addToast,
    setBounds,
    setToasts,
    searchLat,
    searchLng,
    setCenter,
    postTypes,
    setLoading,
    chicagoLat,
    chicagoLng,
    activities,
    setSearchLat,
    setSearchLng,
    locationTypes,
    handleActivities,
    handleLocationTypes
  };

  return (
    <AppContext.Provider value={appLocalState}>{children}</AppContext.Provider>
  );
}

export function useApp() {
  return useContext(AppContext);
}
