import { client } from './client';

export const getPages = async () => {
  let {data} = await client.get(`pages`);
  return data;
}

export const getPage = async (id) => {
  let {data} = await client.get(`pages/${id}`);
  return data;
}

export const addPage = async (body) => {
  let {data} = await client.post(`pages`, body);
  return data;
}

export const updatePage = async (id, body) => {
  let {data} = await client.put(`pages/${id}`, body);
  return data;
}

export const deletePage = async (id) => {
  let {data} = await client.delete(`pages/${id}`);
  return data;
}

export const addSection = async (body) => {
  let {data} = await client.post(`sections`, body);
  return data;
}

export const removeSection = async (id) => {
  let {data} = await client.delete(`sections/${id}`);
  return data;
}

export const updateSection = async (id, body) => {
  let {data} = await client.put(`sections/${id}`, body);
  return data;
}

export const addSectionProduct = async (id, body) => {
  let {data} = await client.post(`docs/add-section-product/${id}`, body);
  return data;
}

export const removeSectionProduct = async (id, body) => {
  let {data} = await client.post(`docs/remove-section-product/${id}`, body);
  return data;
}

export const uploadBlogImage = async (body) => {
  let {data} = await client.post(`docs/upload-image`, body);
  return data;
}
