const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const UserIcon = ({ user = {}, btnRef = null, onClick = () => {}, style = {}, className = '' }) => {
  if (user?.id) {
    if (user?.profilePic) {
      return <img className={className} ref={btnRef} onClick={onClick} alt='' src={`${CDN_ROUTE}${user?.profilePic?.filePath}`} style={{ height: 28, width: 28, borderRadius: '50%', border: '1px solid white', objectFit: 'cover', flexShrink: 0, cursor: 'pointer', ...style }} />
    } else {
      let firstLetter = user?.username[0];
      return <div className={className} ref={btnRef} onClick={onClick} style={{ width: 28, height: 28, borderRadius: '50%', border: '1px solid white', textTransform: 'uppercase', color: 'white', flexShrink: 0, fontSize: 15, fontWeight: 700, cursor: 'pointer', ...style }} className='jc-ac'>{firstLetter}</div>
    }
  } else {
    return <img className={className} ref={btnRef} onClick={onClick} alt='' src="/assets/imgs/errorPhoto.png" style={{ height: 28, width: 28, borderRadius: '50%', border: '1px solid white', objectFit: 'cover', flexShrink: 0, cursor: 'pointer', ...style }} />
  }
}

export default UserIcon;
