import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { getBlogs, addBlog } from 'api/blog';
import { IoCogOutline, IoAppsOutline, IoChevronBackOutline } from 'react-icons/io5';
import GetSection, { BlogCard, BlogSettingsModal, BlogSectionsModal } from 'components/BlogParts';
import UserIcon from 'components/UserIcon'
import { v4 as uuidv4 } from 'uuid';
import { Pill, Pill2 } from 'components/Pill';
import { handleDate } from 'utils';
import { useApp } from 'context';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState({});
  const selectedBlogId = selectedBlog?.id || null;

  const handleBlogs = async () => {
    let _blogs = await getBlogs();
    setBlogs(_blogs)
  }

  useEffect(() => {
    if (!selectedBlogId) {
      handleBlogs();
    }
  }, [selectedBlogId])

  const handleAdd = async () => {
    let newBlog = await addBlog({ slug: `/${uuidv4()}` });
    setSelectedBlog(newBlog)
  }

  return(
    <div style={{ width: '100%' }}>
      {!selectedBlog?.id && <AllBlogs blogs={blogs} handleAdd={handleAdd} setSelectedBlog={setSelectedBlog} />}
      {selectedBlog?.id && <BlogPost blog={selectedBlog} handleBlogs={handleBlogs} setSelectedBlog={setSelectedBlog} />}
    </div>
  )
}

export default Blogs;

const AllBlogs = ({ blogs = [], handleAdd, setSelectedBlog }) => {
  const sortedBlogs = blogs?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

  return(
    <div  style={{ background: 'black', height: '100vh', borderLeft: '1px solid rgba(255,255,255,.25)' }}>
      <div style={{ width: '100%', padding: 20 }} className='jb-ac'>
        <div style={{ fontSize: 18, fontWeight: 700 }}>BLOGS</div>
        <div className='btn1' style={{ paddingLeft: 22, paddingRight: 22, width: 200 }} onClick={handleAdd}>ADD BLOG POST</div>
      </div>
      <div className='container'>
        <div className='row mt-2'>
          {sortedBlogs?.map((blog) => <BlogCard blog={blog} key={blog?.id} setSelectedBlog={setSelectedBlog} />)}
        </div>
      </div>
    </div>
  )
}

const BlogPost = ({ blog, handleBlogs, setSelectedBlog }) => {
  const { activities, postTypes } = useApp();
  const [settingsModal, setSettingsModal] = useState(false);
  const [sectionsModal, setSectionsModal] = useState(false);
  const sortedSections = blog?.sections?.sort((a, b) => a.position - b.position);
  const postType = postTypes?.find((pt) => pt?.id === blog?.postTypeId);
  const activity = activities?.find((pt) => pt?.id === blog?.activityId);

  const width = window.innerWidth;
  const height = window.innerHeight;
  const contentHeight = width > 991 ? height - 113 : height - 62;
  const postWidth = (contentHeight / 16) * 9;

  const handleDate = () => {
    if (blog?.createdAt) {
      return format(new Date(blog?.createdAt), 'MM/dd/yyyy')
    } else {
      return null;
    }
  }

  const handleClose = async () => {
    try {
      handleBlogs()
      setSelectedBlog({})
    } catch(err) {
      console.log(err);
    }
  }

  return(
    <>
      <div>
        <div style={{ position: 'fixed', left: 260, top: 0, width: 'calc(100% - 260px)', height: 113, background: 'black' }} className='jc-ac'>HEADER</div>
        <div style={{ position: 'fixed', left: 280, top: 20 }} onClick={handleClose}><IoChevronBackOutline style={{ fontSize: 21 }} /></div>
        <div style={{ position: 'fixed', right: 20, top: 20 }} onClick={() => setSettingsModal(true)}><IoCogOutline style={{ fontSize: 21 }} /></div>
        <div style={{ position: 'fixed', right: 20, top: 60 }} onClick={() => setSectionsModal(true)}><IoAppsOutline style={{ fontSize: 20 }} /></div>
        <div style={{ width: '100%', height: 113, background: 'black' }} />
        <div className='jb-as' style={{ overflow: 'hidden' }}>
          <BlogCover blog={blog} contentHeight={contentHeight} postWidth={postWidth} />
          <div style={{ maxWidth: 1000, width: '100%' }} className='p-4'>
            <div className='d-flex mb-2'>
              <Pill title={activity?.name} />
              <Pill2 title={postType?.name} />
            </div>
            <div className='mb-2 mt-1' style={{ color: 'white', fontSize: 32, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>{blog?.name || "BLOG NAME"}</div>
            <div className='d-flex align-items-center justify-content-start mb-4'>
              <UserIcon user={blog?.user} style={{ height: 40, width: 40, borderRadius: '50%', border: '1px solid white' }} />
              <div style={{ color: 'white', textAlign: 'left', marginLeft: 12 }}>
                <div style={{ fontWeight: 700 }}>{blog?.user?.username}</div>
                <div style={{ fontSize: 12 }}>{handleDate()}</div>
              </div>
            </div>
            <div style={{ height: contentHeight - 180, overflowY: 'auto', overflowX: "hidden" }}>
              {sortedSections?.map((s, key) => (
                <GetSection section={s} key={key} blog={blog} setSelectedBlog={setSelectedBlog} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <BlogSettingsModal blog={blog} open={settingsModal} setOpen={setSettingsModal} setSelectedBlog={setSelectedBlog} />
      <BlogSectionsModal blog={blog} open={sectionsModal} setOpen={setSectionsModal} setSelectedBlog={setSelectedBlog} />
    </>
  )
}

export const BlogCover = ({ blog, contentHeight, postWidth }) => {
  const mainFile = blog?.files?.find((f) => f?.fileType === 'main');
  const coverPath = `${CDN_ROUTE}${mainFile?.filePath}`

  if (mainFile?.contentType?.includes("video")) {
    return(
      <video key={coverPath} width={postWidth} height={contentHeight} style={{ width: postWidth, objectFit: 'cover', height: contentHeight, flexShrink: 0 }}>
        <source src={coverPath} />
      </video>
    )
  } else {
    return(
      <img key={coverPath} alt='' src={coverPath} style={{ width: postWidth, objectFit: 'cover', height: contentHeight, flexShrink: 0 }} />
    )
  }
}
