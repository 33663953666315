import { Pill, Pill2 } from 'components/Pill';
import UserIcon from 'components/UserIcon';
import { handleDate } from 'utils';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

export const BlogCard = ({ blog, setSelectedBlog }) => {
  const getCover = (post) => {
    const smallFile = post?.files?.find((f) => f?.fileType === 'small');
    return smallFile?.filePath
  }

  return(
    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-3'>
      <div onClick={() => setSelectedBlog(blog)} style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left' }} className='shadow-sm'>
        <img alt='' src={`${CDN_ROUTE}${getCover(blog)}`} style={{ width: '100%', height: 160, objectFit: 'cover' }} />
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <Pill title={blog?.activity?.name} />
            <Pill2 title={blog?.postType?.name} />
          </div>
          <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.25, height: 40 }}>{blog?.name}</div>
          <div className='d-flex align-items-center justify-content-start mt-2'>
            <UserIcon user={blog?.user} className='mt-2' />
            <div style={{ color: 'white', textAlign: 'left', marginLeft: 12 }}>
              <div style={{ fontWeight: 600 }}>{blog?.user?.username}</div>
              <div style={{ fontSize: 10, lineHeight: 1 }}>{handleDate(blog?.createdAt)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
