import Dash from './pages/Dash';
import Login from './pages/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Dash />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
