import { useApp } from 'context';
import { deletePost } from 'api/posts';
import { useState, useEffect } from 'react';
import { getReports, dismissReport } from 'api/reports';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Reports = () => {
  const { setLoading, addToast } = useApp();
  const [reports, setReports] = useState([]);
  const active = reports?.filter((r) => !r.dismissed);
  // const dismissed = reports?.filter((r) => r.dismissed);

  const handleReports = async () => {
    try {
      setLoading(true);
      let {data} = await getReports();
      setReports(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error getting reports', type: "ERROR" });
    }
  }

  useEffect(() => {
    handleReports();
  //eslint-disable-next-line
  }, []);

  const handleDismiss = async (id) => {
    try {
      setLoading(true);
      await dismissReport(id);
      handleReports()
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error dismissing report', type: "ERROR" });
    }
  }

  const handleDeletePost = async (report) => {
    try {
      setLoading(true);
      await deletePost(report?.post?.id);
      await dismissReport(report?.id);
      handleReports();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error deleting post', type: "ERROR" });
    }
  }

  const getFilePath = (report) => {
    const smallFile = report?.post?.files?.find((pf) => pf?.fileType === 'small');
    return `${CDN_ROUTE}${smallFile?.filePath}`;
  }

  return(
    <div className='container' style={{ padding: 20 }}>
      {active?.map((report, key) => <ReportItem report={report} key={key} getFilePath={getFilePath} handleDeletePost={handleDeletePost} handleDismiss={handleDismiss} />)}
    </div>
  )
}

export default Reports;

const ReportItem = ({ report, getFilePath, handleDismiss, handleDeletePost }) => {
  return(
    <div style={{ borderBottom: '1px solid white' }}>
      <div style={{ width: '100%', padding: '20px 0px', color: 'white' }} className='d-flex justify-content-between'>
        <div className='d-flex' style={{ width: '100%' }}>
          <RIImg report={report} getFilePath={getFilePath} />
          <div style={{ marginLeft: 14,  width: '100%' }}>
            <RITopDetails report={report} handleDeletePost={handleDeletePost} handleDismiss={handleDismiss} />
            <div style={{ fontSize: 12, marginTop: 14, color: "#5c7d6d" }}>Report Content</div>
            <div>{report?.body}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const RIImg = ({ report, getFilePath }) => {
  const [open, setOpen] = useState(false);

  return(
    <>
      <img onClick={() => setOpen(true)} alt='' src={getFilePath(report)} style={{ width: 150, height: 150, objectFit: 'cover', flexShrink: 0 }} />
      {open && (
        <div style={{ position: 'fixed', left: 0, top: 0, width: "100vw", height: '100vh', background: 'rgba(0,0,0,.8)' }} className='jc-ac'>
          <div style={{ position: 'absolute', right: 20, top: 20, color: 'white', cursor: 'pointer' }} onClick={() => setOpen(false)}>X</div>
          <img alt='' src={getFilePath(report)} style={{ height: '100vh', objectFit: 'cover', flexShrink: 0 }} />
        </div>
      )}
    </>
  )
}

const RITopDetails = ({ report, handleDismiss, handleDeletePost }) => {
  return(
    <div className='jb-as' style={{ width: '100%' }}>
      <div className='js-as'>
        <div>
          <div style={{ fontSize: 12, color: "#5c7d6d" }}>Reported By</div>
          {report?.reporter?.username}
        </div>
        <div style={{ marginLeft: 24 }}>
          <div style={{ fontSize: 12, color: "#5c7d6d" }}>Posted By</div>
          {report?.post?.user?.username}
        </div>
      </div>
      <div className='d-flex'>
        <div className='btn1' style={{ paddingLeft: 10, paddingRight: 10, fontSize: 14, height: 40, marginRight: 14, width: 160 }} onClick={() => handleDismiss(report?.id)}>Dismiss</div>
        <div className='btn-danger' style={{ paddingLeft: 10, paddingRight: 10, fontSize: 14, height: 40, width: 160 }} onClick={() => handleDeletePost(report)}>Delete Post</div>
      </div>
    </div>
  )
}
