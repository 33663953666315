export const addUrlQuery = (url, query) => {
  if (!query || Object.keys(query).length === 0) {
    return url;
  }

  const { limit, offset, order, filter, token, ...rest } = query;

  var fullUrl = `${url}?`;
  if (rest && Object.keys(rest).length > 0) {
    Object.entries(rest).forEach(([key, value]) => {
      fullUrl += `${key}=${value}&`;
    });
  }
  
  if (limit) {
    fullUrl += "limit=" + limit + "&";
  }

  if (offset) {
    fullUrl += "offset=" + offset + "&";
  }

  if (order) {
    fullUrl += "sort_by=" + order + "&";
  }

  if (filter) {
    fullUrl += "filter=" + JSON.stringify(filter) + "&";
  }

  if (token) {
    fullUrl += `token=${token}&`;
  }

  // remove trailing &
  return fullUrl.slice(0, -1);
};
