import { sendUpdateEmail, sendFeatureEmail, sendFeedbackEmail, sendMissYouEmail } from 'api/email';
import { useApp } from 'context';

const Outreach = () => {
  const { setError, setLoading } = useApp();

  const handleUpdateEmail = async () => {
    try {
      setLoading(true);
      await sendUpdateEmail();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err.message);
    }
  }

  const handleFeatureEmail = async () => {
    try {
      setLoading(true);
      await sendFeatureEmail();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err.message);
    }
  }

  const handleFeedbackEmail = async () => {
    try {
      setLoading(true);
      await sendFeedbackEmail();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err.message);
    }
  }

  const handleMissYouEmail = async () => {
    try {
      setLoading(true);
      await sendMissYouEmail();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return(
    <div className='p-3'>
      <div style={{ fontSize: 18, fontWeight: 700 }}>BULK EMAILS</div>
      <EmailRow title='New Update' callback={handleUpdateEmail} />
      <EmailRow title='New Feature' callback={handleFeatureEmail} />
      <EmailRow title='Feedback' callback={handleFeedbackEmail} />
      <EmailRow title='Miss You' callback={handleMissYouEmail} noBorder={true} />
    </div>
  )
}

export default Outreach;

const EmailRow = ({ title, callback = () => {}, noBorder }) => {
  return(
    <div className='jb-ac mt-3 pb-3' style={{ borderBottom: noBorder ? '' : '1px solid rgba(255,255,255,.25)' }}>
      <div>{title}</div>
      <div style={{ width: 150 }}>
        <div onClick={callback} className='btn1'>Send</div>
      </div>
    </div>
  )
}
