import { client } from './client';

export const deletePost = async (id) => {
  try {
    let response = await client.delete(`posts/${id}`);
    return response;
  } catch(err) {
    console.error("API ERROR")
  }
}

export const getPostErrors = async () => {
  try {
    let response = await client.get(`posts`);
    return response
  } catch(err) {
    console.error("API ERROR")
  }
}
