import { useApp } from 'context';
import { useState, useEffect } from 'react';
import {
  getBlog,
  updateSection,
  uploadBlogImage,
  getSectionProducts
} from 'api/blog';
import {
  ContentModal,
  EmbedModal,
  LinkModal,
  VideoModal,
  ImageModal,
  ProductModal
} from './EditModal';
import { useMeasure } from "react-use";
import { uploadFile } from 'api/uploader';
import { IoCogOutline } from 'react-icons/io5';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

export const Embed = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const width = section?.content2 ? `${section?.content2}px` : "100%";
  const height = section?.content3 ? `${section?.content3}px` : 562

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
      setContent2(section?.content2)
      setContent3(section?.content3)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content, content2: content2, content3: content3 });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-3 mt-2 jc-ac' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 32, cursor: 'pointer', fontWeight: 600, minHeight: 30, position: 'relative', width: '100%' }}>
        {section?.content1 && <div style={{ width: width, height: height, position: 'relative' }} dangerouslySetInnerHTML={{ __html: section?.content1 }} />}
        {!section?.content1 && <div>EMBED</div>}
        <div onClick={() => setEditModal(true)} style={{ position: 'absolute', left: -50, top: -5 }}>
          <IoCogOutline style={{ fontSize: 24, color: 'white' }} />
        </div>
      </div>
      {editModal && <EmbedModal setEditModal={setEditModal} content={content} content2={content2} content3={content3} setContent={setContent} setContent2={setContent2} setContent3={setContent3} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const Title = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 32, cursor: 'pointer', fontWeight: 600 }}>{section?.content1 || 'TITLE'}</div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const SubTitle = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 24, cursor: 'pointer', fontWeight: 600 }}>{section?.content1 || 'SUBTITLE'}</div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const SubSubTitle = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try{
      await updateSection(section?.id, { content1: content });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal(true)} style={{ color: 'white', fontSize: 20, cursor: 'pointer', fontWeight: 600 }}>{section?.content1 || 'SUBTITLE'}</div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const Paragraph = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSection(section?.id, { content1: content });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <span className='blog-item-hover' onClick={() => setEditModal(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content1 || 'PARAGRAPH'}</span>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const Bullet = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1)
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSection(section?.id, { content1: content });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='blog-item-hover js-ac mt-1' onClick={() => setEditModal(true)} style={{ color: 'white', cursor: 'pointer' }}>
        <div style={{ width: 4, height: 4, border: '50%', background: 'rgb(92, 125, 109)', borderRadius: '50%', marginRight: 8 }} />{section?.content1 || 'POINT'}
      </div>
      {editModal && <ContentModal setEditModal={setEditModal} content={content} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const Link = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const [editModal, setEditModal] = useState(false);
  const [content, setContent] = useState('');
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1 || "")
      setHref(section?.href || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateSection(section?.id, { content1: content, href: href });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setEditModal(false)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Content', type: "ERROR" });
    }
  }

  const lastP = blog?.sections?.find((bs) => bs?.position === section?.position - 1 && bs?.type === 'Paragraph');
  const nextP = blog?.sections?.find((bs) => bs?.position === section?.position + 1 && bs?.type === 'Paragraph');
  const hasLast = lastP?.id;
  const hasNext = nextP?.id;
  const margin = hasLast && hasNext ? 'ms-1 me-1' : hasLast ? 'ms-1' : hasNext ? 'me-1' : '';

  return(
    <>
      <span className={`blog-item-hover ${margin}`} onClick={() => setEditModal(true)} style={{ color: 'rgb(92, 125, 109)', cursor: 'pointer' }}>{section?.content1 || 'LINK'}</span>
      {editModal && <LinkModal setEditModal={setEditModal} href={href} content={content} setHref={setHref} setContent={setContent} handleCancel={handleCancel} handleUpdate={handleUpdate}/>}
    </>
  )
}

export const ImageText = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let fileId = await uploadFile(file, "main", true);
        await updateSection(section?.id, { href: href, content1: fileId });
        let updatedBlog = await getBlog(blog?.id);
        setSelectedBlog(updatedBlog)
        setLoading(false);
      } catch(err) {
        setLoading(false);
        addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
        <div className='col-12 col-lg-6 pt-2'>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

export const TextImage = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let fileId = await uploadFile(file, "main", true);
        await updateSection(section?.id, { href: href, content1: fileId });
        let updatedBlog = await getBlog(blog?.id);
        setSelectedBlog(updatedBlog)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6 pt-2'>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

export const TitleTextImage = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal2, setEditModal2] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent2(section?.content2 || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let fileId = await uploadFile(file, "main", true);
        await updateSection(section?.id, { href: href, content1: fileId });
        let updatedBlog = await getBlog(blog?.id);
        setSelectedBlog(updatedBlog)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC2 = () => {
    handleUpdate({ content2: content2 })
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal2(true)} style={{ color: 'white', fontSize: 24, cursor: 'pointer', fontWeight: 600 }}>{section?.content2 || 'SUBTITLE'}</div>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal2 && <ContentModal setEditModal={setEditModal2} content={content2} setContent={setContent2} handleCancel={() => setEditModal2(false)} handleUpdate={handleC2} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

export const ImageTitleText = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [editModal2, setEditModal2] = useState(false);
  const [editModal3, setEditModal3] = useState(false);

  const [content2, setContent2] = useState("");
  const [content3, setContent3] = useState("");
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
      setContent2(section?.content2 || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleImgHref = async (file) => {
    if (file) {
      try {
        setLoading(true);
        let fileId = await uploadFile(file, "main", true);
        await updateSection(section?.id, { href: href, content1: fileId });
        let updatedBlog = await getBlog(blog?.id);
        setSelectedBlog(updatedBlog)
        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    } else {
      handleUpdate({ href: href })
    }
  }

  const handleC2 = () => {
    handleUpdate({ content2: content2 })
  }

  const handleC3 = () => {
    handleUpdate({ content3: content3 })
  }

  const handleUpdate = async (updates) => {
    try {
      setLoading(true);
      await updateSection(section?.id, updates);
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
        </div>
        <div className='col-12 col-lg-6'>
          <div className='blog-item-hover mb-1 mt-2' onClick={() => setEditModal2(true)} style={{ color: 'white', fontSize: 24, cursor: 'pointer', fontWeight: 600 }}>{section?.content2 || 'SUBTITLE'}</div>
          <span className='blog-item-hover' onClick={() => setEditModal3(true)} style={{ color: 'white', cursor: 'pointer' }}>{section?.content3 || 'PARAGRAPH'}</span>
        </div>
      </div>
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleImgHref} href={href} setHref={setHref} />}
      {editModal2 && <ContentModal setEditModal={setEditModal2} content={content2} setContent={setContent2} handleCancel={() => setEditModal2(false)} handleUpdate={handleC2} />}
      {editModal3 && <ContentModal setEditModal={setEditModal3} content={content3} setContent={setContent3} handleCancel={() => setEditModal3(false)} handleUpdate={handleC3} />}
    </>
  )
}

export const Video = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 || "https://www.youtube.com/embed/EnGzLlwmjSU?si=ZMYVlRsmJ6XUsvSP";
  const [content, setContent] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const [editModal, setEditModal] = useState(false);
  const width = section?.content2 ? `${section?.content2}px` : "100%";
  const height = section?.content3 ? `${section?.content3}px` : 562;

  useEffect(() => {
    if (editModal) {
      setContent(section?.content1 || "")
      setContent2(section?.content2 || "")
      setContent3(section?.content3 || "")
    }
    //eslint-disable-next-line
  }, [])

  const handleCancel = () => {
    setContent("")
    setEditModal(false)
  }

  const handleUpdate = async (file) => {
    try {
      setLoading(true);
      let updates = {};
      await updateSection(section?.id, updates);
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog)
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Updating Video', type: "ERROR" });
    }
  }

  return(
    <div style={{ width: width, height: height }}>
      <iframe
        className='video mb-2 mt-2'
        title='Youtube player'
        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
        src={`${path}/autoplay=0`}>
      </iframe>
      <div onClick={() => setEditModal(true)} style={{ position: 'absolute', left: -50, top: 10, zIndex: 1000000 }}>
        <IoCogOutline style={{ fontSize: 24, color: 'white' }} />
      </div>
      {editModal && <VideoModal setEditModal={setEditModal} content={content} content2={content2} content3={content3} setContent={setContent} setContent2={setContent2} setContent3={setContent3} handleCancel={handleCancel} handleUpdate={handleUpdate} />}
    </div>
  )
}

export const Image = ({ blog, section, setSelectedBlog }) => {
  const { setLoading, addToast } = useApp();
  const path = section?.content1 ? `${CDN_ROUTE}${section?.content1}` : '/defaultImg.png';
  const [editModal, setEditModal] = useState(false);
  const [href, setHref] = useState('');

  useEffect(() => {
    if (editModal) {
      setHref(section?.href || "")
    }
    //eslint-disable-next-line
  }, [editModal])

  const handleUpdate = async (file) => {
    try {
      setLoading(true);
      let fileId = await uploadFile(file, "main", true);
      await updateSection(section?.id, { href: href, content1: fileId });
      let updatedBlog = await getBlog(blog?.id);
      setSelectedBlog(updatedBlog);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      addToast({ title: "Error", body: 'Error Uploading Image', type: "ERROR" });
    }
  }

  return(
    <>
      <img className='mb-2 mt-2' alt='' style={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }} src={path} onClick={() => setEditModal(true)} />
      {editModal && <ImageModal setEditModal={setEditModal} path={path} handleUpdate={handleUpdate} href={href} setHref={setHref} />}
    </>
  )
}

export const Spacer = ({ section }) => {
  let type = section?.sectionType;
  let isLG = type === 'Spacer LG' || type === 'Spacer';
  let isMD = type === 'Spacer MD';
  let height = isLG ? 50 : isMD ? 30 : 12;

  return(
    <div className='blog-item-hover' style={{ width: '100%', height: height }} />
  )
}

export const Line = () => {
  return(
    <div className='blog-item-hover mt-3 mb-3' style={{ width: '100%', height: 1, background: 'rgba(255,255,255,.25)' }} />
  )
}

const defaultProduct = { imageUrl: '/defaultImg.png', title: 'PRODUCT NAME', description: 'PRODUCT DESCRIPTION AND DETAILS AND ALL THAT', price: '$29.99', merchantName: 'OUTSYDE'  }

export const Product = ({ blog, section, setSelectedBlog }) => {
  const [ref, { width }] = useMeasure();
  const [editModal, setEditModal] = useState(false);
  const [productData, setProductData] = useState([])
  const products = productData?.length > 0 ? productData : [defaultProduct]
  let imgSize = width * .3;

  const handleProductData = async () => {
    try {
      let data = await getSectionProducts(section?.id);
      setProductData(data);
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleProductData()
  }, [])

  return(
    <>
      <div className='row blog-item-hover mb-2 mt-3' onClick={() => setEditModal(true)} style={{ cursor: 'pointer' }}>
        {products?.map((p, key) => (
          <div key={p?.id || key} className='col-12 col-xl-6 mt-3'>
            <div style={{ width: '100%', background: "#000" }} className='shadow-sm js-ac'>
              <img alt='' src={p?.imageUrl} style={{ width: 100, height: 70, objectFit: 'cover', flexShrink: 0 }} />
              <div className='pt-2 pb-2 ps-3 pe-3 flex-col jb-as' style={{ height: 70, width: '100%' }}>
                <div className='truncate' style={{ fontWeight: 600, lineHeight: 1 }}>{p?.name}</div>
                <div className='jb-ac' style={{ width: '100%' }}>
                  <div style={{ cursor: 'pointer', lineHeight: 1, color: '#7d5a71', fontSize: 12, fontWeight: 700, textTransform: 'uppercase', textDecoration: 'none' }}>{p?.merchant?.name}</div>
                  <div style={{ color: '#4f7361', fontSize: 12, fontWeight: 700 }}>{p?.price}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {editModal && <ProductModal editModal={editModal} setEditModal={setEditModal} section={section} existingProducts={productData} handleProductData={handleProductData} />}
    </>
  )
}
