import { client } from './client';

const createMultipart = async (params) => {
  try {
    let { data } = await client.post(`files/create-multipart`, params);
    return data
  } catch(err) {
    console.log(err);
  }
}

const getSigned = async (params) => {
  try {
    let { data } = await client.post(`files/sign-parts`, params);
    return data
  } catch(err) {
    console.log(err);
  }
}

const completeMultipart = async (params) => {
  try {
    let { data } = await client.post(`files/complete-multipart`, params);
    return data
  } catch(err) {
    console.log(err);
  }
}

class Uploader {
  constructor(options) {
    this.chunkSize = 1024 * 1024 * 6
    this.file = options.file
    this.aborted = false
    this.uploadedSize = 0
    this.progressCache = {}
    this.fileId = null
    this.fileKey = null
    this.onProgressFn = () => {}
    this.onErrorFn = () => {}
    this.onCompleteFn = () => {}
    this.waiting = []
    this.started = []
    this.completed = []
    this.filePath = null
    this.numberOfparts = null
    this.activeConnections = 0
    this.fileType = options.fileType
    this.noCallback = options.noCallback
  }

  start() {
    this.initialize()
  }

  async initialize() {
    try {
      const initializeReponse = await createMultipart({ fileType: this.fileType, contentType: this.file.type, size: this.file.size });
      const AWSFileDataOutput = initializeReponse;
      this.fileId = AWSFileDataOutput.UploadId
      this.fileKey = AWSFileDataOutput.Key
      this.numberOfparts = Math.ceil(this.file.size / this.chunkSize);
      const signedUrls = await getSigned({
        uploadId: this.fileId,
        name: this.fileKey,
        parts: this.numberOfparts
      });
      this.waiting = signedUrls?.map((s, idx) => ({ signedUrl: s, PartNumber: idx + 1 }))
      this.sendNext();
    } catch (error) {
      console.log(error);
      return error
    }
  }

  sendNext() {
    if (this.activeConnections < 5) {
      const remaining = this.waiting.filter((p) => !this.started.includes(p.PartNumber));
      if (remaining?.length > 0) {
        const sorted = remaining.sort((a, b) => a.PartNumber - b.PartNumber)
        const part = sorted[0];
        const sentSize = (part.PartNumber - 1) * this.chunkSize
        const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)
        this.upload(chunk, part);
      }
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    try {
      let data = await completeMultipart({
        uploadId: this.fileId,
        name: this.fileKey,
        type: this.file.name,
        // noCallback: this.noCallback
      });

      this.onCompleteFn(data?.id)
    } catch(err) {
      return err
    }
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part, event) {
    if (this.file) {
      if (event.type === "progress" || event.type === "error" || event.type === "abort") {
        this.progressCache[part] = event.loaded
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      })
    }
  }

  upload(chunk, part) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        this.started.push(part.PartNumber);
        this.activeConnections = this.activeConnections + 1;

        this.sendNext();

        const xhr = new XMLHttpRequest();
        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)
        xhr.upload.addEventListener("progress", progressListener)
        xhr.addEventListener("error", progressListener)
        xhr.addEventListener("abort", progressListener)
        // xhr.addEventListener("loadend", progressListener)
        xhr.open("PUT", part.signedUrl)

        xhr.onloadend = () => {
          // const progressListener = this.handleProgress.bind(this, part.PartNumber - 1)
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader("ETag")

            if (ETag) {
              this.activeConnections = this.activeConnections - 1;
              this.completed.push(part.PartNumber);

              if (this.completed.length === this.numberOfparts) {
                this.sendCompleteRequest();
              } else {
                this.sendNext();
              }

              resolve(xhr.status)
            }
          }
        }

        xhr.onerror = (error) => {
          reject(error)
        }

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"))
        }

        xhr.send(chunk)
      }
    })
  }

  onComplete(onComplete) {
    this.onCompleteFn = onComplete
    return this
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress
    return this
  }

  onError(onError) {
    this.onErrorFn = onError
    return this
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort()
      })

    this.aborted = true
  }
}

export const uploadFile = (file, fileType, noCallback) =>
  new Promise((resolve, reject) => {
    let percentage = undefined
    const uploader = new Uploader({ file, fileType, noCallback })

     uploader
       .onComplete((fileId) => {
         resolve(fileId)
       })
       .onError((error) => {
         reject(error)
       })

     uploader.start()
  })
