import { client } from './client';
import { addUrlQuery } from './utils';

export const getUser = async (id, query) => {
  const url = addUrlQuery(`users`, query);
  const { data } = await client.get(url);
  return data;
}

export const getUsers = async (query) => {
  const url = addUrlQuery(`users`, query);
  const { data } = await client.get(url);
  return data;
}

export const getType = async () => {
  const { data } = await client.get(`auth/get-type`);
  return data;
}

export const getUsersOverview = async () => {
  const { data } = await client.get('admin/users/overview');
  return data
}
