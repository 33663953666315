import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCode, confirmCode, login } from '../api/auth';

const Login = ({ status, setStatus }) => {
  const navigate = useNavigate()
  const [code, setCode] = useState('');
  const [selected, setSelected] = useState('');
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const handleCode = async () => {
    let res = await getCode({ requester: selected });

    if (res?.status === 200) {
      setStep(1);
    }
  }

  const handleCodeConfirm = async () => {
    let res = await confirmCode({ requester: selected, code: code });
    if (res?.status === 200) {
      setStep(2)
      // navigate('/dash');
    }
  }

  const handleSignIn = async () => {
    try {
      await login(username, password);
      navigate('/');
    } catch(err) {

    }
  }

  return(
    <div style={{ width: '100%', height: '100vh', background: '#19181a' }} className='jc-ac flex-col'>
      <img alt='' src='/logoText.png' style={{ width: '100%', maxWidth: 400, marginBottom: 12 }} />
      <Step3 password={password} setPassword={setPassword} username={username} setUsername={setUsername} handleSignIn={handleSignIn} />
    </div>
  )
}

export default Login;

const Step1 = ({ selected, setSelected, handleCode }) => {
  return(
    <>
      <div className='btn1' style={{ maxWidth: 400, marginBottom: 12, background: selected === 'harry' ? '' : '#19181a', border: '2px solid #5c7d6d' }} onClick={() => setSelected('harry')}>HARRY</div>
      <div className='btn1' style={{ maxWidth: 400, marginBottom: 12, background: selected === 'tony' ? '' : '#19181a', border: '2px solid #5c7d6d' }} onClick={() => setSelected('tony')}>TONY</div>
      <div className='btn1' style={{ maxWidth: 400, marginBottom: 12, background: selected === 'parker' ? '' : '#19181a', border: '2px solid #5c7d6d' }} onClick={() => setSelected('parker')}>PARKER</div>
      <div className='btn1' style={{ maxWidth: 400, marginBottom: 12, border: '2px solid #5c7d6d' }} onClick={handleCode}>SIGN IN</div>
    </>
  )
}

const Step2 = ({ code, setCode, handleCodeConfirm }) => {
  return(
    <>
      <input value={code} onChange={(e) => setCode(e.target.value)} style={{ maxWidth: 400 }} placeholder='CODE' className='input' />
      <div className='btn1' style={{ maxWidth: 400, marginBottom: 12, border: '2px solid #5c7d6d' }} onClick={handleCodeConfirm}>NEXT</div>
    </>
  )
}

const Step3 = ({ username, setUsername, password, setPassword, handleSignIn }) => {
  return(
    <>
      <input value={username} onChange={(e) => setUsername(e.target.value)} style={{ maxWidth: 400 }} placeholder='USERNAME' className='input' />
      <input value={password} onChange={(e) => setPassword(e.target.value)} style={{ maxWidth: 400 }} placeholder='PASSWORD' className='input' type='password' />
      <div className='btn1' style={{ maxWidth: 400, marginBottom: 12, border: '2px solid #5c7d6d' }} onClick={handleSignIn}>SIGN IN</div>
    </>
  )
}
